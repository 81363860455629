class BookingDeleteReasonFactory {
  getBookingDeleteReason (data) {
    return {
      id: data.id,
      name: data.name
    }
  }
}

export default new BookingDeleteReasonFactory()
