export default class EditableUserBusiness {
  constructor (userData) {
    this.address = userData.address
    this.cif = userData.cif
    this.facebookProfile = userData.facebookProfile
    this.instagramProfile = userData.instagramProfile
    this.linkedInProfile = userData.linkedInProfile
    this.name = userData.name
    this.phoneNumber = userData.phoneNumber
    this.location = userData.location
    this.twitterProfile = userData.twitterProfile
    this.web = userData.web
    this.zipcode = userData.zipcode
    this.sectors = userData.sectors
  }

  updateLocation (aLocation) {
    this.country = aLocation.country
    this.province = aLocation.administrative
    this.city = aLocation.name
  }
}
