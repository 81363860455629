import UserBusiness from './UserBusiness'
import UserTechnician from './UserTechnician'

export default class User {
  constructor (userData) {
    this.id = userData.id
    this.lastTimeAcceptedTerms = userData.lastTimeAcceptedTerms
    this.email = userData.email
    this.firstName = userData.firstName
    this.lastName = userData.lastName
    this.slug = userData.slug

    this.business = null
    this.technician = null

    if (userData.business) {
      this.business = new UserBusiness(userData.business)
    }

    if (userData.technician) {
      this.technician = new UserTechnician(userData.technician)
    }
  }

  get getBusiness () {
    return this.business
  }

  get getTechnician () {
    return this.technician
  }

  get getId () {
    return this.id
  }

  get hasNotAcceptedTerms () {
    return this.lastTimeAcceptedTerms === null
  }

  get hasAcceptedTerms () {
    return this.lastTimeAcceptedTerms !== null
  }

  get getEmail () {
    return this.email
  }

  get hasCompleteName () {
    return this.firstName !== '' && this.firstName !== undefined && this.firstName !== null
  }

  get getCompleteName () {
    if (this.firstName === '' || this.firstName === undefined || this.firstName === null) {
      return '- -'
    }

    return this.firstName + ' ' + this.lastName
  }

  get getFirstName () {
    return this.firstName
  }

  get getLastName () {
    return this.lastName
  }

  updateSlug (slug) {
    this.slug = slug
  }

  get getSlug () {
    return this.slug
  }

  update (userData) {
    this.firstName = userData.firstName
    this.lastName = userData.lastName
    this.completeName = userData.firstName + ' ' + userData.lastName
    this.lastTimeAcceptedTerms = userData.lastTimeAcceptedTerms
  }

  updateAcceptTermsOfService (acceptTermsOfServiceDate) {
    this.lastTimeAcceptedTerms = acceptTermsOfServiceDate
  }
}
