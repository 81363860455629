export const HANDLER_NATIVE = 'avify'
export const HANDLER_WORKOUT = 'workout'

export default class BookingPosition {
  constructor (bookingPositionData) {
    this.id = bookingPositionData.id
    this.pricePerDay = bookingPositionData.pricePerDay
    this.language = bookingPositionData.language
    this.freelance = bookingPositionData.freelance
    this.opened = bookingPositionData.opened
    this.drivingLicenses = bookingPositionData.drivingLicenses
    this.roles = bookingPositionData.roles
    this.skills = bookingPositionData.skills
    this.title = bookingPositionData.title
    this.annotation = bookingPositionData.annotation
    this.startDate = bookingPositionData.startDate
    this.endDate = bookingPositionData.endDate
    this.jobandtalent = bookingPositionData.jobandtalent
    this.handler = bookingPositionData.handler
  }

  get getId () {
    return this.id
  }

  get getPricePerDay () {
    return String(this.pricePerDay).replace('.', ',')
  }

  get getPrice () {
    return String(this.pricePerDay * this.getDayDifference()).replace('.', ',')
  }

  getDayDifference () {
    const diff = new Date(this.endDate) - new Date(this.startDate)
    return 1 + Math.floor(diff / 3600000 / 24)
  }

  get getLanguage () {
    return this.language
  }

  get getFreelance () {
    return this.freelance
  }

  get getOpened () {
    return this.opened
  }

  get getDrivingLicenses () {
    return this.drivingLicenses
  }

  get getRoles () {
    return this.roles
  }

  get getTitle () {
    return this.title
  }

  get getAnnotation () {
    return this.annotation
  }

  get getSkills () {
    return this.skills
  }

  get getStartDate () {
    if (!this.startDate) {
      return null
    }
    return new Date(this.startDate.replace(' ', 'T'))
  }

  get getEndDate () {
    if (!this.endDate) {
      return null
    }
    return new Date(this.endDate.replace(' ', 'T'))
  }

  get getJobandtalent () {
    return this.jobandtalent
  }

  get getHandler () {
    return this.handler
  }

  get getNamedHandler () {
    return ['Native', 'JobandTalent'][this.handler]
  }

  get getEventDate () {
    const startDate = this.getStartDate
    const endDate = this.getEndDate
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getLocalizedMonthNameByMonth (month) {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ][month]
  }

  get getOnlyTime () {
    const startAt = this.getStartDate
    const endAt = this.getEndDate
    return this.lpad(startAt.getHours()) + ':' +
      this.lpad(startAt.getMinutes()) + ' - ' +
      this.lpad(endAt.getHours()) + ':' +
      this.lpad(endAt.getMinutes())
  }

  lpad (str) {
    return str.toString().padStart(2, 0)
  }
}
