import { HTTP } from '../services/http'
import SkillFilterFactory from '../domain/services/filters/SkillFilterFactory'
import RoleFilterFactory from '../domain/services/filters/RoleFilterFactory'
import SectorFilterFactory from '../domain/services/filters/SectorFilterFactory'
import LanguageFilterFactory from '../domain/services/filters/LanguageFilterFactory'
import AttributeFilterFactory from '../domain/services/filters/AttributeFilterFactory'
import SearchUserItemFactory from '../domain/services/SearchUserItemFactory'

const URL_SEARCH_COUNTERS = 'api/search/counters'
const URL_SEARCH_USERS = 'api/search'
const URL_SEARCH_ATTRIBUTES = '/api/search/attributes'

export function getSearchCountersApi () {
  return HTTP.get(URL_SEARCH_COUNTERS)
    .then(response => {
      return {
        skills: iterateCreateSkills(response.data.skills),
        sectors: iterateCreateSectors(response.data.sectors),
        roles: iterateCreateRoles(response.data.roles),
        languages: iterateCreateLanguage(response.data.languages)
      }
    })
}

function iterateCreateSkills (skills) {
  return skills.map(skill => SkillFilterFactory.getSkillFilter(skill))
}

function iterateCreateRoles (roles) {
  return roles.map(role => RoleFilterFactory.getRoleFilter(role))
}

function iterateCreateSectors (sectors) {
  return sectors.map(sector => SectorFilterFactory.getSectorFilter(sector))
}

function iterateCreateLanguage (languages) {
  return languages.map(language => LanguageFilterFactory.getLanguageFilter(language))
}

export function getAllSkills () {
  return HTTP.get(URL_SEARCH_ATTRIBUTES)
    .then(response => iterateCreateAttributes(response.data.collection))
}

function iterateCreateAttributes (attributes) {
  return attributes.map(attribute => AttributeFilterFactory.getAttributeFilter(attribute))
}

export function getCommunityResults (filters) {
  const skillsIds = filters.skills.map(skill => skill.id)
  const rolesIds = filters.roles.map(role => role.id)
  const sectorsIds = filters.sectors.map(sector => sector.id)
  const languagesIds = filters.languages.map(language => language.id)
  const latitudes = filters.locations.map(location => location.latitude)
  const longitudes = filters.locations.map(location => location.longitude)

  if (filters.latitude !== null) {
    latitudes.push(filters.latitude.toString())
  }
  if (filters.longitude !== null) {
    longitudes.push(filters.longitude.toString())
  }

  return HTTP.get(URL_SEARCH_USERS, {
    params: {
      skills: skillsIds.join(','),
      roles: rolesIds.join(','),
      sectors: sectorsIds.join(','),
      languages: languagesIds.join(','),
      locations: '',
      name: filters.name,
      radio: filters.radio,
      latitude: latitudes.join(','),
      longitude: longitudes.join(','),
      page: filters.currentPaginationPage - 1
    }
  })
    .then(res => {
      return {
        users: iterateCreateUsers(res.data.collection),
        total: res.data.pagination.totalItems
      }
    })
}

function iterateCreateUsers (users) {
  return users.map(user => SearchUserItemFactory.getUser(user))
}
