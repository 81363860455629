<template>
  <div id="app">
    <b-overlay :show=showOverlay no-wrap opacity="0.2" :fixed="true" z-index="2000"></b-overlay>
    <confirmation-modal :visible="showConfirmationModal"></confirmation-modal>
    <router-view/>
  </div>
</template>

<script>
import ConfirmationModal from './components/Modal/ConfirmationModal'
export default {
  name: 'App',
  components: { ConfirmationModal },
  computed: {
    showOverlay () {
      return this.$store.state.sendFormSaving
    },
    showConfirmationModal () {
      return this.$store.state.showConfirmationModal
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
</style>
