
export default {
  loggedIn (state) {
    return state.auth.token !== null
  },
  loggedUser (state) {
    return state.loggedUser
  },
  publicUser (state) {
    return state.publicUser
  },
  hasUserData (state) {
    return Object.entries(state.loggedUser).length !== 0
  },
  bookingEventTypes (state) {
    return state.bookingEventTypes
  },
  bookingDocs (state) {
    return state.bookingDocs
  },
  bookingOpened (state) {
    return state.bookingOpened
  },
  bookingClosed (state) {
    return state.bookingClosed
  },
  bookingExpired (state) {
    return state.bookingExpired
  },
  technicianBookingNotifications (state) {
    return state.technicianBookingNotifications
  },
  hardwareBookingNotifications (state) {
    return state.hardwareBookingNotifications
  },
  languages (state) {
    const languages = state.languages.map(language => ({
      value: language.id,
      text: language.name
    }))
    languages.unshift({ value: null, text: 'Idioma' })
    return languages
  },
  levels (state) {
    const levels = state.levels.map(level => ({
      value: level.id,
      text: level.name
    }))
    levels.unshift({ value: null, text: 'Nivel' })
    return levels
  },
  getSectorsList (state) {
    return state.sectors.map(sector => ({
      value: sector.id,
      text: sector.name
    }))
  },
  getRolesList (state) {
    return state.roles.map(role => ({
      value: role.id,
      text: role.name
    }))
  },
  getSkillsList (state) {
    return state.skills.map(skill => ({
      value: skill.id,
      text: skill.name
    }))
  },
  getDrivingLicensesList (state) {
    return state.drivingLicenses.map(drivingLicense => ({
      value: drivingLicense.id,
      text: drivingLicense.name
    }))
  },
  getSearchData (state) {
    return state.searchData.skills.map(allSkill => ({
      id: allSkill.id,
      name: allSkill.name
    }))
  },
  getSearchDataSkills (state) {
    return state.searchData.skills
  },
  getSearchDataRoles (state) {
    return state.searchData.roles
  },
  getSearchDataSectors (state) {
    return state.searchData.sectors
  },
  getSearchDataLanguages (state) {
    return state.searchData.languages
  },
  getAllSkills (state) {
    return state.allSkills
  },
  filterSkills (state) {
    return state.filters.skills
  },
  filterRoles (state) {
    return state.filters.roles
  },
  filterSectors (state) {
    return state.filters.sectors
  },
  filterLanguages (state) {
    return state.filters.languages
  },
  filterLocations (state) {
    return state.filters.locations
  },
  filterRadio (state) {
    return state.filters.radio
  },
  selectedFilters (state) {
    return state.filters.selectedFilters
  },
  filteredUsers (state) {
    return state.filteredUsers
  },
  totalResults (state) {
    return state.filters.totalResults
  },
  currentPaginationPage (state) {
    return state.filters.currentPaginationPage
  },
  telegramSession (state) {
    return state.telegramSession
  }
}
