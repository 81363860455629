import HardwareBookingContacted from '../models/HardwareBookingContacted'

class HardwareBookingContactedFactory {
  get (data) {
    const hardwareBookingContacted = {
      id: data.id,
      user: data.user,
      userTechnician: data.user.technician,
      hardwareOwnerAccepted: data.hardwareOwnerAccepted,
      businessAccepted: data.businessAccepted,
      favorite: data.favorite ? data.favorite : false
    }

    return new HardwareBookingContacted(hardwareBookingContacted)
  }
}

export default new HardwareBookingContactedFactory()
