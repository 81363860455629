import RoleFilter from '../../models/filters/RoleFilter'

class SectorFilterFactory {
  getSectorFilter (filterData) {
    const auth = {
      id: filterData.id,
      name: filterData.name
    }

    return new RoleFilter(auth)
  }
}

export default new SectorFilterFactory()
