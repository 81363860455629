export default class Project {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.venue = data.venue
    this.venueExtraName = data.venueExtraName
    this.startAt = data.startAt
    this.endAt = data.endAt
    this.managers = data.managers
    this.nonManagers = data.nonManagers
    this.teams = data.teams
    this.docs = data.docs
    this.hardware = data.hardware
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getVenue () {
    return this.venue
  }

  get getVenueExtraName () {
    return this.venueExtraName
  }

  get getStartAt () {
    return new Date(this.startAt.replace(' ', 'T'))
  }

  get getEndAt () {
    return new Date(this.endAt.replace(' ', 'T'))
  }

  get getManagers () {
    return this.managers
  }

  get getNonManagers () {
    return this.nonManagers
  }

  get getTeams () {
    return this.teams
  }

  get getDocs () {
    return this.docs
  }

  get getHardware () {
    return this.hardware
  }
}
