export default class Hardware {
  constructor (data) {
    this.id = data.id
    this.brand = data.brand
    this.productType = data.productType
    this.name = data.name
    this.description = data.description
    this.visible = data.visible
  }

  get getId () {
    return this.id
  }

  get getBrand () {
    return this.brand
  }

  get getProductType () {
    return this.productType
  }

  get getName () {
    return this.name
  }

  get getDescription () {
    return this.description
  }

  get isVisible () {
    return this.visible
  }
}
