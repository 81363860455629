import Project from '../models/Project'

class ProjectFactory {
  get (data) {
    const project = {
      id: data.id,
      name: data.name,
      venue: data.venue?.id,
      venueExtraName: data.venueExtraName,
      startAt: data.startAt,
      endAt: data.endAt,
      managers: data.managers,
      nonManagers: data.nonManagers,
      teams: data.teams,
      docs: data.docs,
      hardware: data.hardware
    }

    return new Project(project)
  }
}

export default new ProjectFactory()
