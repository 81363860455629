export default {
  all (state) {
    return state.all
  },
  current (state) {
    return state.current
  },
  unselectedEmployees (state, getters) {
    return getters.projectEmployees.filter(({ id: id1 }) =>
      !getters.teamMembers.some(({ id: id2 }) => id2 === id1))
  },
  teamMembers (state) {
    return state.current.teams
      .flatMap(({ members }) => members)
      .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
  },
  projectEmployees (state) {
    return [...state.current.managers, ...state.current.nonManagers]
  },
  limboEmployees (state, getters) {
    return getters.teamMembers.filter(({ id: id1 }) =>
      !getters.projectEmployees.some(({ id: id2 }) => id2 === id1))
  },
  allExternalEmployees (state) {
    return state.externalEmployees
  },
  allExternalHardware (state) {
    return state.externalHardware
  }
}
