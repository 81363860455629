import Vue from 'vue'
import Vuex from 'vuex'
import {
  getUser,
  updateUserApi,
  updateUserTechnicianApi,
  updateUserSlugApi,
  updateUserDescriptionApi,
  sendPrivateMessageApi,
  getLanguages,
  getLevels,
  updateUserPasswordApi,
  getUserBySlugApi,
  getUserByUUIDApi,
  getSectors,
  replaceUserSectorsApi,
  getRoles,
  replaceUserRolesApi,
  getSkills,
  replaceUserSkillsApi,
  getDrivingLicenses,
  replaceUserDrivingLicensesApi,
  sendSuggestionsApi,
  acceptTermsOfServiceApi,
  getRegisterUserBusiness,
  getRegisterUserTechnician,
  addFavoriteTechnicianApi,
  deleteFavoriteTechnicianApi,
  updateUserTechnicianFreelanceApi,
  updateUserTechnicianAvailableApi,
  getBusinessEmployeesApi
} from '../api/AvifyApi'
import {
  getJwtToken,
  getRefreshToken,
  getRecoveryPassword,
  updatePasswordFromRecoveryApi,
  getEmailValidationApi,
  getGoogleConsentUrl,
  getFacebookConsentUrl,
  getAppleConsentUrl,
  getGoogleLoginUrl,
  getFacebookLoginUrl
} from '../api/AvifyAuthApi'
import { createCertificationApi, updateCertificationApi, deleteCertificationApi } from '../api/AvifyCertificationsApi'
import {
  createBookingApi,
  updateBookingApi,
  createBookingPositionApi,
  createJobandTalentBookingPositionApi,
  updateJobandTalentBookingPositionApi,
  updateBookingPositionApi,
  getBookingById,
  getBookingDocs,
  getBookingEventTypes,
  getBookingOpened,
  getBookingClosed,
  getBookingExpired,
  getPositionInboxApi,
  getPositionPendingApi,
  getPositionAcceptedApi,
  getPositionRejectedApi,
  broadcastBookingApi,
  contactedTechnicianFromBusinessApi,
  getBookingDeleteReasonsApi,
  addDeleteReasonsApi,
  totalContactedTechnicianFromBusinessApi,
  updateContactedTechnicianAsTechnicianApi,
  updateContactedTechnicianFromBusinessApi,
  getContactedTechnicianAsTechnicianApi
} from '../api/AvifyBookingApi'
import { createLanguageApi, updateLanguageApi, deleteLanguageApi } from '../api/AvifyLanguagesApi'
import {
  uploadTechnicianCoverImageApi,
  uploadTechnicianAvatarImageApi,
  uploadBusinessCoverImageApi,
  uploadBusinessAvatarImageApi
} from '../api/AvifyImagesApi'
import { getSearchCountersApi, getCommunityResults, getAllSkills } from '../api/AvifySearchApi'
import { createHardwareApi, updateHardwareApi, deleteHardwareApi, getHardwareBrandsApi } from '../api/AvifyHardwareApi'
import { getJobCategoriesApi, createJobApi, updateJobApi, deleteJobApi } from '../api/AvifyJobApi'
import { getProductTypesApi } from '../api/AvifyProductType'
import { getProjectsApi, getProjectByIdApi, updateProjectApi, deleteProjectApi } from '../api/AvifyProjectApi'
import { getAllHardwareApi } from '../api/business/AvifyHardwareApi'

Vue.use(Vuex)

export default {
  login ({ commit }, credentials) {
    const authApi = getJwtToken(credentials)
    return authApi
      .then(res => {
        commit('login', res.data)
        return res
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async getGoogleConsentUrl () {
    const googleConsentUrlApi = getGoogleConsentUrl()
    return googleConsentUrlApi
      .then(res => res.data)
      .catch(error => { return Promise.reject(error) })
  },
  async getFacebookConsentUrl () {
    const getFacebookConsentUrlApi = getFacebookConsentUrl()
    return getFacebookConsentUrlApi
      .then(res => res.data)
      .catch(error => { return Promise.reject(error) })
  },
  async getAppleConsentUrl () {
    const getAppleConsentUrlApi = getAppleConsentUrl()
    return getAppleConsentUrlApi
      .then(res => res.data)
      .catch(error => { return Promise.reject(error) })
  },
  async getGoogleLoginUrl (state, googleLoginData) {
    const getGoogleLoginUrlApi = getGoogleLoginUrl(googleLoginData)
    return getGoogleLoginUrlApi
      .then(res => {
        state.commit('login', res.data)
      })
      .catch(error => { return Promise.reject(error) })
  },
  async getFacebookLoginUrl (state, facebookLoginData) {
    const getFacebookLoginUrlApi = getFacebookLoginUrl(facebookLoginData)
    return getFacebookLoginUrlApi
      .then(res => {
        state.commit('login', res.data)
      })
      .catch(error => { return Promise.reject(error) })
  },
  registerUserBusiness ({ commit }, registerUserData) {
    const registerUserApi = getRegisterUserBusiness(registerUserData)
    return registerUserApi
      .then(res => res)
      .catch(error => { return Promise.reject(error) })
  },
  registerUserTechnician ({ commit }, registerUserData) {
    const registerUserApi = getRegisterUserTechnician(registerUserData)
    return registerUserApi
      .then(res => res)
      .catch(error => { return Promise.reject(error) })
  },
  recoveryUserPassword ({ commit }, recoveryPassword) {
    const recoveryPasswordApi = getRecoveryPassword(recoveryPassword)
    return recoveryPasswordApi
      .then(res => res)
      .catch(error => { return Promise.reject(error) })
  },
  refreshToken ({ commit }) {
    const authApi = getRefreshToken()
    return authApi
      .then(res => {
        commit('login', res.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  emailValidation (context, emailValidationData) {
    const emailValidationApi = getEmailValidationApi(emailValidationData)
    return emailValidationApi
      .then(res => res)
      .catch(error => {
        return Promise.reject(error)
      })
  },
  logout (context) {
    context.commit('logout')
  },
  getUser: async ({ commit }) => {
    const userInfoApi = getUser()
    return userInfoApi
      .then(response => {
        commit('updateLoggedUser', response)
        return response
      })
      .catch(error => {
        return error
      })
  },
  updateUser ({ commit }, userData) {
    const updateUser = updateUserApi(userData)
    return updateUser
      .then(response => {
        commit('updateLoggedUserAfterUpdate', userData)
        return response
      })
  },
  updateUserTechnician ({ commit }, userData) {
    const updateUserTechnician = updateUserTechnicianApi(userData)
    return updateUserTechnician
      .then(response => {
        commit('updateLoggedUserTechnician', userData)
        return response
      })
  },
  updateUserSlug ({ commit }, userSlug) {
    const updateUserSlug = updateUserSlugApi(userSlug)
    return updateUserSlug
      .then(response => {
        commit('updateLoggedUserSlug', userSlug)
        return response
      })
  },
  updateUserDescription ({ commit }, descriptionData) {
    const updateUserDescription = updateUserDescriptionApi(descriptionData)
    return updateUserDescription
      .then(response => {
        commit('updateLoggedUserDescription', descriptionData)
        return response
      })
  },
  getPublicUserInfo: async ({ commit }, slug) => {
    const userInfoApi = getUserBySlugApi(slug)
    return userInfoApi
      .then(response => {
        return response
      })
  },
  getUserByUUID: async ({ commit }, uuid) => {
    const userInfoApi = getUserByUUIDApi(uuid)
    return userInfoApi
      .then(response => {
        commit('updatePublicUser', response)
        return response
      })
  },
  updateCustomUrl ({ commit }, newCustomUrl) {
    commit('updateCustomUrl', newCustomUrl)
  },
  getLanguages (context) {
    const languagesApi = getLanguages()
    return languagesApi
      .then(response => context.commit('getLanguages', response))
  },
  getBookingById: async (context, bookingId) => {
    return await getBookingById(bookingId).then(res => res)
  },
  createBooking (context, bookingData) {
    return createBookingApi(bookingData)
  },
  createBookingPosition (context, bookingPositionData) {
    return createBookingPositionApi(bookingPositionData)
  },
  createJobandTalentBookingPosition (context, bookingPositionData) {
    return createJobandTalentBookingPositionApi(bookingPositionData)
  },
  updateJobandTalentBookingPosition (context, bookingPositionData) {
    return updateJobandTalentBookingPositionApi(bookingPositionData)
  },
  updateBooking (context, data) {
    return updateBookingApi(data)
  },
  updateBookingPosition (context, bookingPositionData) {
    return updateBookingPositionApi(bookingPositionData)
  },
  getBookingEventTypes (context) {
    const bookingEventTypesApi = getBookingEventTypes()
    return bookingEventTypesApi
      .then(response => context.commit('getBookingEventTypes', response))
  },
  getBookingDocs (context) {
    const bookingDocsApi = getBookingDocs()
    return bookingDocsApi
      .then(response => context.commit('getBookingDocs', response))
  },
  getBookingOpened (context) {
    const bookingsOpenedApi = getBookingOpened()
    return bookingsOpenedApi
      .then(response => context.commit('getBookingOpened', response))
  },
  getBookingClosed (context) {
    const bookingsClosedApi = getBookingClosed()
    return bookingsClosedApi
      .then(response => context.commit('getBookingClosed', response))
  },
  getBookingExpired (context) {
    const bookingsExpiredApi = getBookingExpired()
    return bookingsExpiredApi
      .then(response => context.commit('getBookingExpired', response))
  },
  getPositionInbox: async (context) => {
    const positionInboxApi = getPositionInboxApi()
    return positionInboxApi.then(res => res)
  },
  getPositionPending: async (context) => {
    const positionPendingApi = getPositionPendingApi()
    return positionPendingApi.then(res => res)
  },
  getPositionAccepted: async (context) => {
    const positionAcceptedApi = getPositionAcceptedApi()
    return positionAcceptedApi.then(res => res)
  },
  getPositionRejected: async (context) => {
    const positionRejectedApi = getPositionRejectedApi()
    return positionRejectedApi.then(res => res)
  },
  broadcastBooking (context, bookingData) {
    const bookingApi = broadcastBookingApi(bookingData)
    return bookingApi
      .then(response => response)
  },
  contactedTechnicianFromBusiness (context, data) {
    const api = contactedTechnicianFromBusinessApi(data)
    return api
      .then(response => response)
  },
  totalContactedTechnicianFromBusiness (context, data) {
    const api = totalContactedTechnicianFromBusinessApi(data)
    return api
      .then(response => response)
  },
  updateContactedTechnicianFromBusiness (context, data) {
    const api = updateContactedTechnicianFromBusinessApi(data)
    return api
      .then(response => response)
  },
  getLevels (context) {
    const levelsApi = getLevels()
    return levelsApi
      .then(response => context.commit('getLevels', response))
  },
  getSectors (context) {
    const sectorsApi = getSectors()
    return sectorsApi
      .then(response => context.commit('getSectors', response))
  },
  async replaceUserSectors ({ commit }, sectorsData) {
    const sectors = []
    sectorsData.forEach(item => {
      if (item.id !== null) {
        sectors.push(item.id)
      }
    })
    const replaceUserSectors = replaceUserSectorsApi({ sectors: sectors })
    return replaceUserSectors
      .then(response => {
        commit('updateUserSectors', sectorsData)
        return response
      })
  },
  getRoles (context) {
    const rolesApi = getRoles()
    return rolesApi
      .then(response => context.commit('getRoles', response))
  },
  replaceUserRoles ({ commit }, rolesData) {
    const roles = []
    rolesData.forEach(item => {
      if (item.id !== null) {
        roles.push(item.id)
      }
    })
    const replaceUserRoles = replaceUserRolesApi({ roles: roles })
    return replaceUserRoles
      .then(response => {
        commit('updateUserRoles', rolesData)
        return response
      })
  },
  getSkills (context) {
    const skillsApi = getSkills()
    return skillsApi
      .then(response => context.commit('getSkills', response))
  },
  getSearchData (context) {
    const searchCountersApi = getSearchCountersApi()
    return searchCountersApi
      .then(response => {
        context.commit('getSearchData', response)
      })
  },
  getAllSkills (context) {
    const allSkillsApi = getAllSkills()
    return allSkillsApi
      .then(response => {
        context.commit('getAllSkills', response)
      })
  },
  replaceUserSkills ({ commit }, skillsData) {
    const skills = []
    skillsData.forEach(item => {
      if (item.id !== null) {
        skills.push(item.id)
      }
    })
    const replaceUserSkills = replaceUserSkillsApi({ skills: skills })
    return replaceUserSkills
      .then(response => {
        commit('updateUserSkills', skillsData)
        return response
      })
  },
  getDrivingLicenses (context) {
    const drivingLicensesApi = getDrivingLicenses()
    return drivingLicensesApi
      .then(response => context.commit('getDrivingLicenses', response))
  },
  replaceUserDrivingLicenses ({ commit }, drivingLicensesData) {
    const drivingLicenses = []
    drivingLicensesData.forEach(item => {
      if (item.id !== null) {
        drivingLicenses.push(item.id)
      }
    })
    const replaceUserDrivingLicenses = replaceUserDrivingLicensesApi({ drivingLicenses: drivingLicenses })
    return replaceUserDrivingLicenses
      .then(response => {
        commit('updateUserDrivingLicenses', drivingLicensesData)
        return response
      })
  },
  updateUserPassword ({ commit }, changePasswordData) {
    return updateUserPasswordApi(changePasswordData)
  },
  updatePasswordFromRecovery (context, updatePasswordData) {
    return updatePasswordFromRecoveryApi(updatePasswordData)
  },
  createCertification ({ commit }, createCertification) {
    const certificationsApi = createCertificationApi(createCertification)
    return certificationsApi
      .then(certification => commit('addCertification', certification))
  },
  updateCertification ({ commit }, updateCertification) {
    const certificationsApi = updateCertificationApi(updateCertification)
    return certificationsApi
      .then(certification => commit('updateCertification', certification))
  },
  deleteCertification ({ commit }, certificationId) {
    const certificationsApi = deleteCertificationApi(certificationId)
    return certificationsApi
      .then(() => commit('deleteCertification', certificationId))
  },
  lockSendFormSaving: (state) => {
    state.commit('updateSendFormSaving', true)
  },
  unlockSendFormSaving: (state) => {
    state.commit('updateSendFormSaving', false)
  },
  createLanguage ({ commit }, createLanguage) {
    const languagesApi = createLanguageApi(createLanguage)
    return languagesApi
      .then(language => commit('addLanguage', language))
  },
  updateLanguage ({ commit }, editableLanguage) {
    const languagesApi = updateLanguageApi(editableLanguage)
    return languagesApi
      .then(languageLevel => commit('updateLanguage', languageLevel))
  },
  deleteLanguage ({ commit }, languageId) {
    const languagesApi = deleteLanguageApi(languageId)
    return languagesApi
      .then(() => commit('deleteLanguage', languageId))
  },
  sendSuggestion ({ commit }, suggestion) {
    const suggestionsApi = sendSuggestionsApi(suggestion)
    return suggestionsApi
      .then((response) => {
        return response
      })
  },
  showConfirmationModal: (state) => {
    state.commit('updateConfirmationModal', true)
  },
  hideConfirmationModal: (state) => {
    state.commit('updateConfirmationModal', false)
  },
  uploadTechnicianCoverImage ({ commit }, imageData) {
    const uploadCoverImageApiClient = uploadTechnicianCoverImageApi(imageData)
    return uploadCoverImageApiClient
      .then((response) => {
        commit('updateTechnicianUserCoverUrl', response.data)
        return response
      })
  },
  uploadTechnicianAvatarImage ({ commit }, imageData) {
    const uploadAvatarImageApiClient = uploadTechnicianAvatarImageApi(imageData)
    return uploadAvatarImageApiClient
      .then((response) => {
        commit('updateTechnicianUserAvatarUrl', response.data)
        return response
      })
  },
  uploadBusinessCoverImage ({ commit }, imageData) {
    const uploadCoverImageApiClient = uploadBusinessCoverImageApi(imageData)
    return uploadCoverImageApiClient
      .then((response) => {
        commit('updateBusinessUserCoverUrl', response.data)
        return response
      })
  },
  uploadBusinessAvatarImage ({ commit }, imageData) {
    const uploadAvatarImageApiClient = uploadBusinessAvatarImageApi(imageData)
    return uploadAvatarImageApiClient
      .then((response) => {
        commit('updateBusinessUserAvatarUrl', response.data)
        return response
      })
  },
  updateFilters ({ commit }, filtersTypeData) {
    commit('updateFilters', filtersTypeData)
  },
  updateFilterSkills ({ commit }, filterSkills) {
    commit('updateFilterSkills', filterSkills)
  },
  updateFilterRoles ({ commit }, filterRoles) {
    commit('updateFilterRoles', filterRoles)
  },
  updateFilterSectors ({ commit }, filterSectors) {
    commit('updateFilterSectors', filterSectors)
  },
  updateFilterLanguages ({ commit }, filterLanguages) {
    commit('updateFilterLanguages', filterLanguages)
  },
  updateFilterRadios ({ commit }, filterRadios) {
    commit('updateFilterRadios', filterRadios)
  },
  updateFilterLocations ({ commit }, filterLocations) {
    commit('updateFilterLocations', filterLocations)
  },
  updateSelectedFilters ({ commit }, selectedFilters) {
    commit('updateSelectedFilters', selectedFilters)
  },
  updateFilterName ({ commit }, name) {
    commit('updateFilterName', name)
  },
  updateAlgoliaFilter ({ commit }, algoliaData) {
    commit('updateAlgoliaFilter', algoliaData)
  },
  clearAlgoliaFilter (state) {
    state.commit('clearAlgoliaFilter')
  },
  removeSelectedFilters ({ commit }, filterId) {
    commit('removeSelectedFilters', filterId)
  },
  resetFilters ({ commit }) {
    commit('resetFilters')
  },
  loginApple ({ commit }, appleData) {
    commit('login', appleData)
  },
  findCommunityResults: async (state) => {
    const searchCommunityResults = getCommunityResults(state.state.filters)
    return searchCommunityResults
      .then(response => {
        state.commit('udpateFilteredUsers', response.users)
        state.commit('updateTotalResults', response.total)
        return response.users
      })
  },
  updateCurrentPaginationPage (state, currentPage) {
    state.commit('updateCurrentPaginationPage', currentPage)
  },
  sendPrivateMessage (state, privateMessage) {
    const sendPrivateMessage = sendPrivateMessageApi(privateMessage)
    return sendPrivateMessage
      .then(response => {
        return response
      })
  },
  acceptTermsOfService (state) {
    const acceptTermsOfService = acceptTermsOfServiceApi()
    return acceptTermsOfService
      .then(response => {
        state.commit('updateAcceptTermsOfService', response)
        return response
      })
  },
  addFavoriteTechnician ({ commit }, technicianId) {
    const addFavoriteTechnician = addFavoriteTechnicianApi(technicianId)
    return addFavoriteTechnician
      .then(res => res)
      .catch(error => { return Promise.reject(error) })
  },
  deleteFavoriteTechnician ({ commit }, technicianId) {
    const addFavoriteTechnician = deleteFavoriteTechnicianApi(technicianId)
    return addFavoriteTechnician
      .then(res => res)
      .catch(error => { return Promise.reject(error) })
  },
  updateUserTechnicianFreelancer ({ commit }, freelancer) {
    const updateUserTechnician = updateUserTechnicianFreelanceApi(freelancer)
    return updateUserTechnician
      .then(response => {
        return response
      })
  },
  updateUserTechnicianAvailable ({ commit }, available) {
    const updateUserTechnician = updateUserTechnicianAvailableApi(available)
    return updateUserTechnician
      .then(response => {
        return response
      })
  },
  getBusinessEmployees () {
    return getBusinessEmployeesApi()
      .then(response => {
        return response
      })
  },
  getBusinessHardware () {
    return getAllHardwareApi()
      .then(response => {
        return response
      })
  },
  getBookingDeleteReasons ({ commit }) {
    const updateUserTechnician = getBookingDeleteReasonsApi()
    return updateUserTechnician
      .then(response => {
        return response
      })
  },
  addDeleteReasons (context, addDeleteReasonsData) {
    return addDeleteReasonsApi(addDeleteReasonsData)
  },
  updateContactedTechnicianAsTechnician (context, contactedTechnicianData) {
    return updateContactedTechnicianAsTechnicianApi(contactedTechnicianData)
  },
  getContactedTechnicianAsTechnician (context) {
    getContactedTechnicianAsTechnicianApi()
      .then(response => context.commit(
        'getTechnicianBookingNotifications',
        response.collection
      ))
  },

  createHardware (_, data) {
    return createHardwareApi(data)
      .then(response => response)
  },
  updateHardware (_, hardware) {
    return updateHardwareApi(hardware)
      .then(response => response)
  },
  deleteHardware (_, id) {
    return deleteHardwareApi(id)
      .then(response => response)
  },
  getHardwareBrands () {
    return getHardwareBrandsApi()
      .then(response => response)
  },

  getJobCategories () {
    return getJobCategoriesApi()
      .then(response => response)
  },
  createJob (_, data) {
    return createJobApi(data)
      .then(response => response)
  },
  updateJob (_, data) {
    return updateJobApi(data)
      .then(response => response)
  },
  deleteJob (_, id) {
    return deleteJobApi(id)
      .then(response => response)
  },

  getProductTypes () {
    return getProductTypesApi()
      .then(response => response)
  },

  getProjects () {
    return getProjectsApi()
      .then(response => response)
  },

  getProjectById (_, id) {
    return getProjectByIdApi(id)
      .then(response => response)
  },

  updateProject (_, data) {
    return updateProjectApi(data)
      .then(response => response)
  },

  deleteProject (_, id) {
    return deleteProjectApi(id)
      .then(response => response)
  },

  updateTelegramSession ({ commit }, session) {
    commit('updateTelegramSession', session)
  }
}
