import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue'),
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Logout.vue'),
    meta: {
      loadData: true,
      public: false
    }
  },
  {
    path: '/register/technician',
    name: 'register-technician',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterTechnician.vue'),
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/complete/technician',
    name: 'complete-technician',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompleteTechnician.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/complete/business',
    name: 'complete-business',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompleteBusiness.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/register/business',
    name: 'register-business',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterBusiness.vue'),
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/recovery',
    name: 'recovery-password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestPasswordRecovery.vue'),
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/v/password-recovery/:recoveryToken',
    name: 'password-recovery',
    component: () => import('../views/PasswordRecovery.vue'),
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/s/tokens/:token/:refreshToken',
    name: 'set-tokens',
    beforeEnter: (to, from, next) => {
      console.log(to.params)
      store.commit('login', to.params)
      next('dashboard')
    },
    meta: {
      loadData: false,
      public: true
    }
  },
  {
    path: '/v/validate/:emailValidationToken',
    name: 'email-validation',
    component: () => import('../views/EmailValidation.vue'),
    meta: {
      loadData: false,
      public: 'full'
    }
  },
  {
    path: '/home/user',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/home/user/technician',
    name: 'dashboard-technician',
    component: () => import('../views/DashboardTechnician.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/home/user/business',
    name: 'dashboard-business',
    component: () => import('../views/DashboardBusiness.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/home/config',
    name: 'config-user-account',
    component: () => import('../views/ConfigUserAccount.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/home/business/config',
    name: 'config-user-business-account',
    component: () => import('../views/ConfigUserBusinessAccount.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/public/:slug',
    name: 'public-user-profile',
    component: () => import('../views/PublicUserProfile.vue'),
    meta: {
      requiresAuth: false,
      loadData: true,
      public: false
    }
  },
  {
    path: '/technician/:id',
    name: 'private-technician-profile',
    component: () => import('../views/PrivateUserProfile.vue'),
    props: route => ({ openedMessageBox: route.query.openedMessageBox === 'true' }),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/inbox',
    name: 'positions-inbox',
    component: () => import('@/views/BookingPositionInboxList.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/inbox/:id',
    name: 'positions-inbox-position',
    component: () => import('@/views/BookingPositionInbox.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/inbox/hardware/:id',
    name: 'positions-inbox-hardware-position',
    component: () => import('@/views/HardwareBookingPositionInbox.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/pending/:id',
    name: 'positions-pending-position',
    component: () => import('@/views/BookingPositionPending.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/pending/hardware/:id',
    name: 'positions-pending-hardware-position',
    component: () => import('@/views/HardwareBookingPositionPending.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/accepted/:id',
    name: 'positions-accepted-position',
    component: () => import('@/views/BookingPositionAccepted.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/accepted/hardware/:id',
    name: 'positions-accepted-hardware-position',
    component: () => import('@/views/HardwareBookingPositionAccepted.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/rejected/:id',
    name: 'positions-rejected-position',
    component: () => import('@/views/BookingPositionRejected.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/rejected/hardware/:id',
    name: 'positions-rejected-hardware-position',
    component: () => import('@/views/HardwareBookingPositionRejected.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/pending',
    name: 'positions-pending',
    component: () => import('@/views/BookingPositionPendingList.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/accepted',
    name: 'positions-accepted',
    component: () => import('@/views/BookingPositionAcceptedList.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/positions/rejected',
    name: 'positions-rejected',
    component: () => import('@/views/BookingPositionRejectedList.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/bookings/opened',
    name: 'bookings-opened',
    component: () => import('@/views/BookingsOpened.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/bookings/closed',
    name: 'bookings-closed',
    component: () => import('@/views/BookingsClosed.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/bookings/expired',
    name: 'bookings-expired',
    component: () => import('@/views/BookingsExpired.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/bookings/:id',
    name: 'view-booking',
    component: () => import('@/views/ViewBooking.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/bookings/:id/position/:positionId',
    name: 'view-position-list',
    component: () => import('@/views/ListBookingPositions.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/projects',
    name: 'project-list',
    component: () => import('@/views/project/list/ProjectList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/create',
    name: 'project-create',
    component: () => import('@/views/project/create/ProjectCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/detail/:id',
    name: 'detail-project',
    component: () => import('@/views/project/detail/DetailProject.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId',
    name: 'edit-project',
    component: () => import('@/views/project/edit/EditProject.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/technician-booking/opened',
    name: 'opened-technician-booking',
    component: () => import('@/views/project/technician-booking/list/opened/OpenedTechnicianBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/technician-booking/closed',
    name: 'closed-technician-booking',
    component: () => import('@/views/project/technician-booking/list/closed/ClosedTechnicianBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/technician-booking/expired',
    name: 'expired-technician-booking',
    component: () => import('@/views/project/technician-booking/list/expired/ExpiredTechnicianBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/technician-booking/:technicianBookingId',
    name: 'detail-technician-booking',
    component: () => import('@/views/project/technician-booking/detail/DetailTechnicianBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/technician-booking/:technicianBookingId/technician-position/:technicianPositionId',
    name: 'detail-technician-position',
    component: () => import('@/views/project/technician-booking/technician-position/detail/native/DetailTechnicianPositionNative.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/hardware-booking/opened',
    name: 'opened-hardware-booking',
    component: () => import('@/views/project/hardware-booking/list/opened/OpenedHardwareBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/hardware-booking/closed',
    name: 'closed-hardware-booking',
    component: () => import('@/views/project/hardware-booking/list/closed/ClosedHardwareBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/hardware-booking/expired',
    name: 'expired-hardware-booking',
    component: () => import('@/views/project/hardware-booking/list/expired/ExpiredHardwareBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/hardware-booking/:hardwareBookingId',
    name: 'detail-hardware-booking',
    component: () => import('@/views/project/hardware-booking/detail/DetailHardwareBooking.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/hardware-booking/:hardwareBookingId/hardware-position/:hardwarePositionId',
    name: 'detail-hardware-position',
    component: () => import('@/views/project/hardware-booking/hardware-position/detail/DetailHardwarePosition.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/venue/:venueId',
    name: 'detail-venue',
    component: () => import('@/views/project/venue/DetailVenue.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employees',
    name: 'employee-list',
    component: () => import('@/views/employee/list/EmployeeList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employees/create',
    name: 'employee-create',
    component: () => import('@/views/employee/create/EmployeeCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employees/edit',
    name: 'employee-edit',
    component: () => import('@/views/employee/edit/EmployeeEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hardware',
    name: 'hardware-list',
    component: () => import('@/views/hardware/list/HardwareList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hardware/create',
    name: 'hardware-create',
    component: () => import('@/views/hardware/create/HardwareCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hardware/edit',
    name: 'hardware-edit',
    component: () => import('@/views/hardware/edit/HardwareEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/Finder.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/fb-registration',
    name: 'fb-registration',
    component: () => import('../views/FacebookRegistration.vue'),
    meta: {
      requiresAuth: false,
      loadData: false,
      public: true
    }
  },
  {
    path: '/google-registration',
    name: 'google-registration',
    component: () => import('../views/GoogleRegistration.vue'),
    meta: {
      requiresAuth: false,
      loadData: false,
      public: true
    }
  },
  {
    path: '/apple-registration',
    name: 'apple-registration',
    component: () => import('../views/AppleRegistration.vue'),
    meta: {
      requiresAuth: false,
      loadData: false,
      public: true
    }
  },
  {
    path: '/accept-tos',
    name: 'accept-tos',
    component: () => import('../views/AcceptTermsOfService.vue'),
    meta: {
      requiresAuth: true,
      loadData: true,
      public: false
    }
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: () => import('../views/Authorize.vue'),
    meta: {
      requiresAuth: true,
      loadData: false,
      public: false
    }
  },
  {
    path: '*',
    name: 'default',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      requiresAuth: false,
      loadData: false,
      public: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'default') {
    next({ name: 'login' })
    store.dispatch('unlockSendFormSaving')
    return
  }

  if (to.meta.public === 'full') {
    next()
    store.dispatch('unlockSendFormSaving')
    return
  }

  const userIsLoggedIn = store.getters.loggedIn
  if (userIsLoggedIn && to.meta.public) {
    next({ name: 'dashboard' })
    store.dispatch('unlockSendFormSaving')
    return
  }

  if (to.meta.public && !to.meta.loadData) {
    store.dispatch('unlockSendFormSaving')
    next()
    return
  }

  const routeRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const hasUserData = store.getters.hasUserData
  if (routeRequiresAuth && !hasUserData && userIsLoggedIn) {
    await store.dispatch('getUser')
      .then(response => {
        if (response.code === 401) {
          next({ name: 'logout' })
        }
      })
  }

  const loggedUser = store.getters.loggedUser
  if (to.name !== 'accept-tos' && userIsLoggedIn && loggedUser.hasNotAcceptedTerms) {
    next({ name: 'accept-tos' })
    return
  }

  if (!routeRequiresAuth || userIsLoggedIn) {
    next()
    store.dispatch('unlockSendFormSaving')

    return
  }

  next({
    name: 'logout'
  })
})

export default router
