import AttributeFilter from '../../models/filters/AttributeFilter'

class AttributeFilterFactory {
  getAttributeFilter (filterData) {
    const filter = {
      code: filterData.id,
      label: filterData.name,
      type: filterData.type
    }

    return new AttributeFilter(filter)
  }
}

export default new AttributeFilterFactory()
