import { HTTP } from '@/services/http'
import HardwareFactory from '@/domain/services/HardwareFactory'

const URL = 'api/users/me/business/hardware'
const URL_TECHNICIAN = 'api/users/me/technician/project/'

const headers = {
  'Content-Type': 'application/json'
}

export function fetchAll () {
  return HTTP.get(URL)
    .then(response => response.data.collection.map(item => HardwareFactory.get(item)))
}

export function fetchAllTechnician (id) {
  const url = URL_TECHNICIAN + id + '/hardware'
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => HardwareFactory.get(item)))
}

export function fetchOneById (id) {
  const url = URL + '/' + id
  return HTTP.get(url)
    .then(response => HardwareFactory.get(response.data))
}

export function createRequest (data) {
  return HTTP.post(URL, data, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function updateRequest (data) {
  const entityToUpdate = Object.assign({}, data)
  const url = URL + '/' + entityToUpdate.id
  delete entityToUpdate.id
  return HTTP.put(url, entityToUpdate, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function deleteRequest (id) {
  const url = URL + '/' + id
  return HTTP.delete(url)
    .then(response => response)
}
