import BookingPosition from '../models/BookingPosition'

class BookingPositionFactory {
  getBookingPosition (bookingData) {
    const bookingPosition = {
      id: bookingData.id,
      pricePerDay: bookingData.pricePerDay,
      language: bookingData.language,
      freelance: bookingData.freelance,
      opened: bookingData.opened,
      drivingLicenses: bookingData.drivingLicenses,
      roles: bookingData.roles,
      skills: bookingData.skills,
      title: bookingData.title,
      annotation: bookingData.annotation,
      startDate: String(bookingData.startDate).replace(' ', 'T'),
      endDate: String(bookingData.endDate).replace(' ', 'T'),
      jobandtalent: bookingData.jobandtalent,
      hiredByAvify: bookingData.hiredByAvify,
      handler: bookingData.handler
    }

    return new BookingPosition(bookingPosition)
  }
}

export default new BookingPositionFactory()
