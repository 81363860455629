import HardwareFactory from '@/domain/services/HardwareFactory'
import { HTTP } from '@/services/http'

const URL_GET_ALL_HARDWARE = 'api/users/me/business/hardware'
const URL_CREATE_HARDWARE = 'api/users/me/business/hardware'
const URL_GET_HARDWARE = 'api/users/me/business/hardware/{id}'
const URL_UPDATE_HARDWARE = 'api/users/me/business/hardware/{id}'
const URL_DELETE_HARDWARE = 'api/users/me/business/hardware/{id}'

const headers = {
  'Content-Type': 'application/json'
}

export function getAllHardwareApi () {
  return HTTP.get(URL_GET_ALL_HARDWARE)
    .then(response => response.data.collection.map(response => HardwareFactory.get(response)))
}

export function createHardwareApi (data) {
  return HTTP.post(URL_CREATE_HARDWARE, data, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function getHardwareApi (id) {
  const url = URL_GET_HARDWARE.replace('{id}', id)
  return HTTP.get(url)
    .then(response => HardwareFactory.get(response.data))
}

export function updateHardwareApi (data) {
  const hardware = Object.assign({}, data)
  const url = URL_UPDATE_HARDWARE.replace('{id}', hardware.id)
  delete hardware.id
  return HTTP.put(url, hardware, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function deleteHardwareApi (id) {
  const url = URL_DELETE_HARDWARE.replace('{id}', id)
  return HTTP.delete(url)
    .then(response => response)
}
