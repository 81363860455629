import { getDefaultState } from '@/store/state'
import Profiles from '@/utils/getProfiles'

const ACCESS_TOKEN = 'access_token'
const ACCESS_REFRESH_TOKEN = 'access_refresh_token'

export default {
  setCurrentProfileTechnician (state) {
    state.currentProfile = Profiles.PROFILE_TECHNICIAN
    localStorage.setItem('currentProfile', state.currentProfile)
  },
  setCurrentProfileBusiness (state) {
    state.currentProfile = Profiles.PROFILE_BUSINESS
    localStorage.setItem('currentProfile', state.currentProfile)
  },
  login (state, auth) {
    const accessToken = auth.token
    const refreshToken = auth.refreshToken

    state.auth = auth

    localStorage.setItem(ACCESS_TOKEN, accessToken)
    localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken)
  },
  refreshLogin (state, auth) {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(ACCESS_REFRESH_TOKEN)
    state.loggedUser = {}
    state.languages = {}
    state.levels = {}

    const accessToken = auth.token
    state.auth.token = accessToken
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  },
  logout (state) {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(ACCESS_REFRESH_TOKEN)
    Object.assign(state, getDefaultState())
  },
  updateLoggedUser (state, user) {
    state.loggedUser = user
  },
  updateLoggedUserAfterUpdate (state, user) {
    state.loggedUser.update(user)
  },
  updateLoggedUserTechnician (state, user) {
    state.loggedUser.update(user)
  },
  updateLoggedUserSlug (state, slugData) {
    state.loggedUser.updateSlug(slugData.slug)
  },
  updateLoggedUserDescription (state, descriptionData) {
    state.loggedUser.getTechnician.updateDescription(descriptionData.description)
  },
  updatePublicUser (state, user) {
    state.publicUser = user
  },
  updateUserSectors (state, sectorData) {
    state.loggedUser.getTechnician.updateSectors(sectorData)
  },
  updateUserRoles (state, rolesData) {
    state.loggedUser.getTechnician.updateRoles(rolesData)
  },
  updateUserSkills (state, skillsData) {
    state.loggedUser.getTechnician.updateSkills(skillsData)
  },
  updateUserDrivingLicenses (state, drivingLicenses) {
    state.loggedUser.getTechnician.updateDrivingLicenses(drivingLicenses)
  },
  updateCustomUrl (state, newCustomUrl) {
    state.loggedUser.updateSlug(newCustomUrl)
  },
  getLanguages (state, languages) {
    state.languages = languages.collection
  },
  getBookingEventTypes (state, bookingEventTypes) {
    state.bookingEventTypes = bookingEventTypes.collection
  },
  getBookingDocs (state, bookingDocs) {
    state.bookingDocs = bookingDocs.collection
  },
  getBookingOpened (state, bookingOpened) {
    state.bookingOpened = bookingOpened
  },
  getBookingClosed (state, bookingClosed) {
    state.bookingClosed = bookingClosed
  },
  getBookingExpired (state, bookingExpired) {
    state.bookingExpired = bookingExpired
  },
  getTechnicianBookingNotifications (state, notifications) {
    state.technicianBookingNotifications = notifications
  },
  getLevels (state, levels) {
    state.levels = levels.collection
  },
  getSectors (state, sectors) {
    state.sectors = sectors.collection
  },
  getRoles (state, roles) {
    state.roles = roles.collection
  },
  getSkills (state, skills) {
    state.skills = skills.collection
  },
  getSearchData (state, searchData) {
    state.searchData = searchData
  },
  getAllSkills (state, allSkills) {
    state.allSkills = allSkills
  },
  getDrivingLicenses (state, drivingLicenses) {
    state.drivingLicenses = drivingLicenses.collection
  },
  getCertifications (state, certifications) {
    state.certifications = certifications
  },
  updateSendFormSaving: (state, isSending) => {
    state.sendFormSaving = isSending
  },
  addCertification (state, certification) {
    state.loggedUser.getTechnician.addCertification(certification)
  },
  updateCertification (state, certification) {
    state.loggedUser.getTechnician.updateCertification(certification)
  },
  deleteCertification (state, certificationId) {
    state.loggedUser.getTechnician.deleteCertification(certificationId)
  },
  addLanguage (state, language) {
    state.loggedUser.getTechnician.addLanguage(language)
  },
  updateLanguage (state, languageLevel) {
    state.loggedUser.getTechnician.updateLanguageLevel(languageLevel)
  },
  deleteLanguage (state, languageId) {
    state.loggedUser.getTechnician.deleteLanguage(languageId)
  },
  updateConfirmationModal (state, isConfirmationModalVisible) {
    state.showConfirmationModal = isConfirmationModalVisible
  },
  updateTechnicianUserCoverUrl (state, userCoverImage) {
    state.loggedUser.getTechnician.cover = userCoverImage
  },
  updateTechnicianUserAvatarUrl (state, userAvatarImage) {
    state.loggedUser.getTechnician.avatar = userAvatarImage
  },
  updateBusinessUserCoverUrl (state, userCoverImage) {
    state.loggedUser.getBusiness.profileCover = userCoverImage
  },
  updateBusinessUserAvatarUrl (state, userAvatarImage) {
    state.loggedUser.getBusiness.avatar = userAvatarImage
  },
  updateFilters (state, filtersTypeData) {
    // state.filters = filtersTypeData
    // console.log(filtersTypeData.data)
    // const type = filtersTypeData.type
    // const data = Array.from(filtersTypeData.data)
    // console.log(state.filters[type])
  },
  updateFilterSkills (state, filterSkills) {
    state.filters.skills = filterSkills
  },
  updateFilterRoles (state, filterRoles) {
    state.filters.roles = filterRoles
  },
  updateFilterSectors (state, filterSectors) {
    state.filters.sectors = filterSectors
  },
  updateFilterLanguages (state, filterLanguages) {
    state.filters.languages = filterLanguages
  },
  updateFilterLocations (state, filterLocations) {
    state.filters.locations = filterLocations
  },
  updateFilterRadios (state, filterRadio) {
    state.filters.radio = filterRadio
  },
  updateFilterName (state, name) {
    state.filters.name = name
  },
  updateAlgoliaFilter (state, algoliaData) {
    state.filters.longitude = algoliaData.getLongitude
    state.filters.latitude = algoliaData.getLatitude
  },
  clearAlgoliaFilter (state) {
    state.filters.longitude = null
    state.filters.latitude = null
  },
  updateSelectedFilters (state, selectedFilters) {
    state.filters.selectedFilters = selectedFilters
  },
  updateTotalResults (state, totalResults) {
    state.filters.totalResults = totalResults
  },
  removeSelectedFilters (state, filterId) {
    Object.entries(state.filters).forEach(function (item) {
      if (!Array.isArray(item[1])) {
        return
      }
      const skillIndexToRemove = item[1].findIndex(
        item => item.id === filterId
      )
      if (skillIndexToRemove > -1) {
        item[1].splice(skillIndexToRemove, 1)
      }
    })
  },
  resetFilters (state) {
    Object.assign(state.filters, getDefaultState().filters)
  },
  udpateFilteredUsers (state, filteredUsers) {
    state.filteredUsers = filteredUsers
  },
  updateCurrentPaginationPage (state, currentPage) {
    state.filters.currentPaginationPage = currentPage
  },
  updateAcceptTermsOfService (state, user) {
    state.loggedUser.updateAcceptTermsOfService(user.lastTimeAcceptedTerms)
  },
  updateTelegramSession (state, session) {
    state.telegramSession = session
  }
}
