import HardwareBrand from '../models/HardwareBrand'

class HardwareBrandFactory {
  get (data) {
    const hardwareBrand = {
      id: data.id,
      name: data.name
    }
    return new HardwareBrand(hardwareBrand)
  }
}

export default new HardwareBrandFactory()
