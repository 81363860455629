import Job from '../models/Job'

class JobFactory {
  get (data) {
    const job = {
      id: data.id,
      name: data.name,
      description: data.description,
      year: data.year,
      roles: data.roles,
      jobCategory: data.jobCategory,
      link: data.link,
      images: data.images
    }

    return new Job(job)
  }
}

export default new JobFactory()
