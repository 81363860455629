import { HTTP } from '../services/http'
import CertificationFactory from '../domain/services/CertificationFactory'

const URL_CERTIFICATION = '/api/users/me/technician/certifications'
const headers = {
  'Content-Type': 'application/json'
}

export function createCertificationApi (createCertificationData) {
  return HTTP.post(URL_CERTIFICATION, createCertificationData, { headers: headers })
    .then((response) => {
      return CertificationFactory.getCertification(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateCertificationApi (updateCertificationData) {
  const certificationId = updateCertificationData.id
  delete updateCertificationData.id
  return HTTP.put(URL_CERTIFICATION + '/' + certificationId, updateCertificationData, { headers: headers })
    .then(() => {
      updateCertificationData.id = certificationId
      return updateCertificationData
    })
}

export function deleteCertificationApi (certificationId) {
  return HTTP.delete(URL_CERTIFICATION + '/' + certificationId)
    .then(() => {})
}
