export default class EditableUser {
  constructor (userData) {
    this.id = userData.id
    this.email = userData.email
    this.firstName = userData.firstName
    this.lastName = userData.lastName
    this.business = userData.business
    this.technician = userData.technician
  }
}
