import Hardware from '../models/Hardware'
import HardwareBrandFactory from './HardwareBrandFactory'
import ProductTypeFactory from './ProductTypeFactory'

class HardwareFactory {
  get (data) {
    const hardware = {
      id: data.id,
      name: data.name,
      description: data.description,
      brand: HardwareBrandFactory.get(data.brand),
      productType: ProductTypeFactory.get(data.productType),
      visible: data.visible
    }
    return new Hardware(hardware)
  }
}

export default new HardwareFactory()
