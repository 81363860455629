import axios from '../services'
import { HTTP } from '../services/http'
import UserFactory from '../domain/services/UserFactory'

const URL_GET_USER_INFO = '/api/users/me'
const URL_SLUG_GET_USER_INFO = '/api/users/s/'
const URL_UUID_GET_USER_INFO = '/api/users/'
const URL_REGISTER_USER_BUSINESS = '/api/users/business'
const URL_REGISTER_USER_TECHNICIAN = '/api/users/technician'
const URL_USER_TECHNICIAN = '/api/users/me/technician'
const URL_GET_LANGUAGES = '/api/languages'
const URL_GET_LEVELS = '/api/language-levels'
const URL_GET_SECTORS = '/api/sectors'
const URL_GET_ROLES = '/api/roles'
const URL_GET_SKILLS = '/api/skills'
const URL_GET_DRIVING_LICENSES = '/api/driving-licenses'
const URL_UPDATE_PASSWORD = '/api/users/me/password'
const URL_REPLACE_SECTORS = '/api/users/me/technician/sectors'
const URL_REPLACE_ROLES = '/api/users/me/technician/roles'
const URL_REPLACE_SKILLS = '/api/users/me/technician/skills'
const URL_REPLACE_DRIVING_LICENSES = '/api/users/me/technician/driving-licenses'
const URL_POST_SUGGESTION = '/api/content/suggestions'
const URL_UPDATE_USER_SLUG = '/api/users/me/slug'
const URL_UPDATE_USER_DESCRIPTION = '/api/users/me/technician/description'
const URL_SEND_PRIVATE_MESSAGE = '/api/users/me/messages'
const URL_ACCEPT_TERMS_OF_SERVICE = '/api/users/me/accept-terms-of-service'
const URL_FAVORITE_TECHNICIAN = '/api/users/me/favorite-technician'
const URL_USER_TECHNICIAN_UPDATE_FREELANCER = '/api/users/me/technician/freelancer'
const URL_USER_TECHNICIAN_UPDATE_AVAILABLE = '/api/users/me/technician/available'
const URL_BUSINESS_EMPLOYEES = '/api/users/me/business/employees'

const headers = {
  'Content-Type': 'application/json'
}

export function getRegisterUserBusiness (registerUser) {
  return axios.post(URL_REGISTER_USER_BUSINESS, {
    firstName: registerUser.firstname,
    lastName: registerUser.lastname,
    email: registerUser.username,
    password: registerUser.password,
    name: registerUser.business_name,
    cif: registerUser.business_cif,
    sectors: registerUser.business_sector,
    address: registerUser.business_address,
    zipcode: registerUser.business_zipcode,
    location: registerUser.business_location,
    phoneNumber: {
      number: registerUser.business_phone,
      prefix: registerUser.business_prefix
    }
  }, {
    headers: headers
  })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getRegisterUserTechnician (registerUser) {
  return axios.post(URL_REGISTER_USER_TECHNICIAN, {
    firstName: registerUser.firstname,
    lastName: registerUser.lastname,
    email: registerUser.username,
    password: registerUser.password,
    image: registerUser.avatarImage,
    extension: registerUser.avatarExtension,
    sectors: registerUser.sectors,
    roles: registerUser.roles,
    skills: registerUser.skills
  }, {
    headers: headers
  })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getUser () {
  return HTTP.get(URL_GET_USER_INFO)
    .then(res => UserFactory.getUser(res.data))
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getUserBySlugApi (userSlug) {
  return HTTP.get(URL_SLUG_GET_USER_INFO + userSlug)
    .then(res => UserFactory.getUser(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getUserByUUIDApi (uuid) {
  return HTTP.get(URL_UUID_GET_USER_INFO + uuid)
    .then(res => UserFactory.getUser(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserPasswordApi (passwordData) {
  return HTTP.put(URL_UPDATE_PASSWORD, passwordData, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserApi (userData) {
  return HTTP.put(URL_GET_USER_INFO, userData, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserTechnicianApi (userData) {
  return HTTP.put(URL_USER_TECHNICIAN, userData, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserSlugApi (userSlug) {
  return HTTP.put(URL_UPDATE_USER_SLUG, userSlug, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserDescriptionApi (descriptionData) {
  return HTTP.put(URL_UPDATE_USER_DESCRIPTION, descriptionData, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function sendPrivateMessageApi (privateMessage) {
  return HTTP.post(URL_SEND_PRIVATE_MESSAGE, {
    receiverId: privateMessage.getTo,
    text: privateMessage.getMessage
  }, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getLanguages () {
  return HTTP.get(URL_GET_LANGUAGES)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getLevels () {
  return HTTP.get(URL_GET_LEVELS)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getSectors () {
  return HTTP.get(URL_GET_SECTORS)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getRoles () {
  return HTTP.get(URL_GET_ROLES)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getSkills () {
  return HTTP.get(URL_GET_SKILLS)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getDrivingLicenses () {
  return HTTP.get(URL_GET_DRIVING_LICENSES)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function replaceUserSectorsApi (sectors) {
  return HTTP.put(URL_REPLACE_SECTORS, sectors, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function replaceUserRolesApi (roles) {
  return HTTP.put(URL_REPLACE_ROLES, roles, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function replaceUserSkillsApi (skills) {
  return HTTP.put(URL_REPLACE_SKILLS, skills, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function replaceUserDrivingLicensesApi (drivingLicenses) {
  return HTTP.put(URL_REPLACE_DRIVING_LICENSES, drivingLicenses, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function sendSuggestionsApi (suggestion) {
  return HTTP.post(URL_POST_SUGGESTION, suggestion, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function acceptTermsOfServiceApi () {
  return HTTP.put(URL_ACCEPT_TERMS_OF_SERVICE)
    .then(res => UserFactory.getUser(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function addFavoriteTechnicianApi (technicianId) {
  return HTTP.post(
    URL_FAVORITE_TECHNICIAN,
    { userTechnicianId: technicianId },
    { headers: headers }
  )
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function deleteFavoriteTechnicianApi (technicianId) {
  return HTTP.delete(URL_FAVORITE_TECHNICIAN + '/' + technicianId)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserTechnicianFreelanceApi (freelancer) {
  return HTTP.put(URL_USER_TECHNICIAN_UPDATE_FREELANCER, { freelancer: freelancer }, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateUserTechnicianAvailableApi (available) {
  return HTTP.put(URL_USER_TECHNICIAN_UPDATE_AVAILABLE, { available: available }, { headers: headers })
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBusinessEmployeesApi () {
  return HTTP.get(URL_BUSINESS_EMPLOYEES)
    .then(res => res.data.collection.map(user => UserFactory.getUser(user)))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function deleteMeApi () {
  return HTTP.delete(URL_GET_USER_INFO)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}
