import { HTTP } from '@/services/http'
import ProjectFactory from '@/domain/services/ProjectFactory'
import EditableUserFactory from '@/domain/services/EditableUserFactory'
import HardwareFactory from '@/domain/services/HardwareFactory'

const URL = 'api/users/me/business/projects'
const URLTechnician = 'api/users/me/technician/projects'

const headers = {
  'Content-Type': 'application/json'
}

export function fetchAll () {
  return HTTP.get(URL)
    .then(response => response.data.collection.map(item => ProjectFactory.get(item)))
}

export function fetchAllByTechnician () {
  return HTTP.get(URLTechnician)
    .then(response => response.data.collection.map(item => ProjectFactory.get(item)))
}

export function fetchOneById (id) {
  const url = URL + '/' + id
  return HTTP.get(url)
    .then(response => ProjectFactory.get(response.data))
}

export function fetchOOneByIdTechnician (id) {
  const url = URLTechnician + '/' + id
  return HTTP.get(url)
    .then(response => ProjectFactory.get(response.data))
}

export function createRequest (data) {
  return HTTP.post(URL, data, { headers })
    .then(response => ProjectFactory.get(response.data))
}

export function updateRequest (data) {
  const entityToUpdate = Object.assign({}, data)
  const url = URL + '/' + entityToUpdate.id
  delete entityToUpdate.id
  return HTTP.put(url, entityToUpdate, { headers })
    .then(response => ProjectFactory.get(response.data))
}

export function deleteRequest (id) {
  const url = URL + '/' + id
  return HTTP.delete(url)
    .then(response => response)
}

export function fetchAllExternalTechnician (id) {
  const url = URL + '/' + id + '/external-employees'
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => EditableUserFactory.getEditableUser(item)))
}

export function fetchAllExternalHardware (id) {
  const url = URL + '/' + id + '/external-hardware'
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => HardwareFactory.get(item)))
}
