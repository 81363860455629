export default class EditableUserTechnician {
  constructor (userData) {
    this.id = userData.id
    this.birthday = userData.birthday
    this.address = userData.address
    this.postalCode = userData.postalCode
    this.phoneNumber = userData.phoneNumber
    this.gender = userData.gender
    this.freelancer = userData.freelancer
    this.description = userData.description
    this.nickname = userData.nickname
    this.linkedInProfile = userData.linkedInProfile
    this.location = userData.location
    this.contactEmail = userData.contactEmail
    this.avatarImage = userData.avatarImage
    this.avatarExtension = userData.avatarExtension
    this.coverImage = userData.coverImage
    this.coverExtension = userData.coverExtension
    this.sectors = userData.sectors
    this.skills = userData.skills
    this.roles = userData.roles
    this.available = userData.available
  }

  updateLocation (location) {
    this.location = location
  }
}
