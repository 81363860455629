export default class SearchUserItem {
  constructor (userData) {
    this.id = userData.id
    this.firstName = userData.firstName
    this.lastName = userData.lastName

    this.location = null
    this.avatar = null
    this.skills = null
    this.roles = null
    this.sectors = null

    if (userData.technician) {
      this.avatar = userData.technician.avatar
      this.location = userData.technician.location
      this.skills = userData.technician.skills
      this.roles = userData.technician.roles
      this.sectors = userData.technician.sectors
    }
  }

  get getId () {
    return this.id
  }

  get hasCompleteName () {
    return this.firstName !== '' && this.firstName !== undefined && this.firstName !== null
  }

  get getCompleteName () {
    if (this.firstName === '' || this.firstName === undefined || this.firstName === null) {
      return '- -'
    }

    return this.firstName + ' ' + this.lastName
  }

  get getFirstName () {
    return this.firstName
  }

  get getLastName () {
    return this.lastName
  }

  get getLocationName () {
    return this.location.name
  }

  get getLocationCountry () {
    return this.location.country
  }

  get hasLocation () {
    return this.getLocationName !== '' &&
      this.getLocationName !== null &&
      this.getLocationName !== 'null' &&
      this.getLocationName !== undefined
  }

  get getLocation () {
    return this.location
  }

  get getCompleteLocation () {
    return this.getLocationName + ', ' + this.getLocationCountry
  }

  get hasAvatar () {
    return this.avatar !== null
  }

  get getAvatar () {
    return this.avatar
  }

  get getSkills () {
    return this.skills
  }

  get getRoles () {
    return this.roles
  }

  get getSectors () {
    return this.sectors
  }
}
