export default class UserTechnician {
  constructor (userTechnicianData) {
    this.id = userTechnicianData.id
    this.birthday = userTechnicianData.birthday
    this.address = userTechnicianData.address
    this.postalCode = userTechnicianData.postalCode
    this.phoneNumber = userTechnicianData.phoneNumber
    this.gender = userTechnicianData.gender
    this.freelancer = userTechnicianData.freelancer
    this.description = userTechnicianData.description
    this.nickname = userTechnicianData.nickname
    this.linkedInProfile = userTechnicianData.linkedInProfile
    this.location = userTechnicianData.location
    this.contactEmail = userTechnicianData.contactEmail
    this.avatar = userTechnicianData.avatar
    this.cover = userTechnicianData.cover
    this.sectors = userTechnicianData.sectors
    this.skills = userTechnicianData.skills
    this.roles = userTechnicianData.roles
    this.drivingLicenses = userTechnicianData.drivingLicenses
    this.languages = userTechnicianData.languages || []
    this.certifications = userTechnicianData.certifications
    this.jobs = userTechnicianData.jobs
    this.hardware = userTechnicianData.hardware
    this.available = userTechnicianData.available
  }

  get getIsEmpty () {
    return typeof this === 'undefined'
  }

  get getId () {
    return this.id
  }

  get getAddress () {
    return this.address
  }

  get getDescription () {
    return this.description
  }

  get hasAcceptedTerms () {
    return this.lastTimeAcceptedTerms !== null
  }

  updateDescription (description) {
    this.description = description
  }

  get getEmail () {
    return this.email
  }

  get getContactEmail () {
    return this.contactEmail
  }

  get hasContactEmail () {
    return this.contactEmail !== '' && this.contactEmail !== null && this.contactEmail !== undefined
  }

  get hasCompleteName () {
    return this.firstName !== '' && this.firstName !== undefined && this.firstName !== null
  }

  get getCompleteName () {
    if (this.firstName === '' || this.firstName === undefined || this.firstName === null) {
      return '- -'
    }

    return this.firstName + ' ' + this.lastName
  }

  get getFirstName () {
    return this.firstName
  }

  get getLastName () {
    return this.lastName
  }

  updateSlug (slug) {
    this.slug = slug
  }

  get getSlug () {
    return this.slug
  }

  get getNickname () {
    return this.nickname
  }

  get hasPhoneNumber () {
    return this.phoneNumber.number !== '' &&
      this.phoneNumber.number !== null &&
      this.phoneNumber.number !== 'null' &&
      this.phoneNumber.number !== undefined
  }

  get getPrefix () {
    if (this.hasPhoneNumber) {
      return this.phoneNumber.prefix
    }
  }

  get getPhoneNumber () {
    if (this.hasPhoneNumber) {
      return this.phoneNumber.number
    }
  }

  get getCompletePhoneNumber () {
    if (this.hasPhoneNumber) {
      return this.phone.prefix + ' ' + this.phone.number
    }
  }

  get hasLinkedInProfile () {
    return this.linkedInProfile !== null && this.linkedInProfile !== '' && this.linkedInProfile !== undefined
  }

  get getlinkedInProfile () {
    return this.linkedInProfile
  }

  get getLocationCountry () {
    return this.location.country
  }

  get getLocationName () {
    return this.location.name
  }

  get hasLocation () {
    return this.getLocationName !== '' &&
      this.getLocationName !== null &&
      this.getLocationName !== 'null' &&
      this.getLocationName !== undefined
  }

  get getCompleteLocation () {
    return this.getLocationName + ', ' + this.getLocationCountry
  }

  get getLocationAdministrative () {
    return this.location.administrative
  }

  get getLanguages () {
    return this.languages
  }

  get getCertifications () {
    return this.certifications
  }

  get getJobs () {
    return this.jobs
  }

  updateJobs (jobs) {
    this.jobs = jobs
  }

  get getHardware () {
    return this.hardware
  }

  updateHardware (hardware) {
    this.hardware = hardware
  }

  get getSectors () {
    if (this.sectors === undefined) {
      return []
    }
    return this.sectors
  }

  updateSectors (sectors) {
    this.sectors = sectors
  }

  get getRoles () {
    if (this.roles === undefined) {
      return []
    }
    return this.roles
  }

  updateRoles (roles) {
    this.roles = roles
  }

  get getSkills () {
    if (!this.skills === undefined) {
      return []
    }
    return this.skills
  }

  updateSkills (skills) {
    this.skills = skills
  }

  get getDrivingLicenses () {
    if (this.drivingLicenses === undefined) {
      return []
    }
    return this.drivingLicenses
  }

  updateDrivingLicenses (drivingLicenses) {
    this.drivingLicenses = drivingLicenses
  }

  addCertification (certification) {
    this.certifications.push(certification)
  }

  updateCertification (certification) {
    const certificationIndexToDelete = this.certifications.findIndex(
      item => item.id === certification.id
    )
    this.certifications[certificationIndexToDelete].update(certification)
  }

  deleteCertification (certificationId) {
    const certificationIndexToDelete = this.certifications.findIndex(
      item => item.id === certificationId
    )
    this.certifications.splice(certificationIndexToDelete, 1)
  }

  addLanguage (language) {
    this.languages.push(language)
  }

  updateLanguageLevel (languageLevel) {
    const languageIndexToDelete = this.languages.findIndex(
      item => item.id === languageLevel.id
    )
    this.languages[languageIndexToDelete].update(languageLevel)
  }

  deleteLanguage (languageId) {
    const languageIndexToDelete = this.languages.findIndex(
      item => item.id === languageId
    )
    this.languages.splice(languageIndexToDelete, 1)
  }

  get hasAvatar () {
    return this.avatar !== null && this.avatar !== []
  }

  get getAvatar () {
    return this.avatar
  }

  get hasCover () {
    return this.cover !== null && this.cover !== [] && this.cover !== undefined
  }

  get getCover () {
    return this.cover
  }

  get isAvailable () {
    return this.available
  }

  update (user) {
    console.log(user)
  }
}
