export default class JobCategory {
  constructor (data) {
    this.id = data.id
    this.name = data.name
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }
}
