export default class getErrorMessagesUtil {
  static getErrorMessage (errorMessage) {
    const dictionary = this.getDictionary()
    const translatedMessage = dictionary.find(dictionaryElement => dictionaryElement.code === errorMessage)

    if (typeof translatedMessage !== 'undefined') {
      return translatedMessage.message
    }

    return 'El campo no es válido'
  }

  static getDictionary () {
    return [
      {
        code: 'password_pattern_not_match',
        message: '8 caracteres mínimo, con al menos una letra y un número'
      },
      {
        code: 'missing_field',
        message: 'Requerido'
      },
      {
        code: 'must_be_string',
        message: 'Formato inválido'
      },
      {
        code: 'not_blank',
        message: 'Valor requerido'
      },
      {
        code: 'invalid_email',
        message: 'Email no válido'
      },
      {
        code: 'invalid_prefix_format',
        message: 'Formato de prefijo no válido (p.ej +34)'
      },
      {
        code: 'invalid_number_format',
        message: 'Formato de número no válido'
      },
      {
        code: 'invalid_postal_code_format',
        message: 'Formato de código postal no válido'
      },
      {
        code: 'invalid_url',
        message: 'URL no válida'
      },
      {
        code: 'must_be_float',
        message: 'Formato de número no válido'
      },
      {
        code: 'weak_password',
        message: 'Contraseña débil. Esta contraseña ha sido comprometida de forma pública'
      },
      {
        code: 'invalid_uuid',
        message: 'UUID no válido'
      },
      {
        code: 'required',
        message: 'Requerido'
      },
      {
        code: 'invalid_file_type',
        message: 'Formato de archivo no válido'
      },
      {
        code: 'invalid_year',
        message: 'Formato de año no válido'
      },
      {
        code: 'invalid_month',
        message: 'Formato de mes no válido'
      },
      {
        code: 'must_be_array_of_uuids',
        message: 'Se esperaba un array de UUIDs'
      },
      {
        code: 'api.password_recovery_request.not_found',
        message: 'Solicitud de cambio de contraseña no encontrada'
      },
      {
        code: 'api.certifications.not_found',
        message: 'Certificación no encontrada'
      },
      {
        code: 'api.certifications.not_owned_by_user',
        message: 'La certificación no pertenece al usuario'
      },
      {
        code: 'api.driving_licenses.not_found',
        message: 'Licencia de conducción no encontrada'
      },
      {
        code: 'api.images.not_found',
        message: 'Imagen no encontrada'
      },
      {
        code: 'api.languages.level_not_found',
        message: 'Nivel de idioma no encontrado'
      },
      {
        code: 'api.languages.not_found',
        message: 'Idioma no encontrado'
      },
      {
        code: 'api.roles.not_found',
        message: 'Rol no encontrado'
      },
      {
        code: 'api.sectors.not_found',
        message: 'Sector no encontrado'
      },
      {
        code: 'api.users.invalid_gender',
        message: 'Género no válido'
      },
      {
        code: 'api.skills.not_found',
        message: 'Habilidad no encontrada'
      },
      {
        code: 'api.users.auth.invalid_password',
        message: 'Contraseña no válida'
      },
      {
        code: 'api.users.email_already_registered',
        message: 'Ya existe una cuenta asociada a este email'
      },
      {
        code: 'api.users.language_not_found',
        message: 'Idioma de usuario no econtrado'
      },
      {
        code: 'api.users.language_not_owned_by_user',
        message: 'El registro de idioma no pertenece al usuario'
      },
      {
        code: 'api.users.not_found',
        message: 'Usuario no encontrado'
      },
      {
        code: 'invalid_slug',
        message: 'URL personalizada no válida'
      },
      {
        code: 'api.users.slug_already_in_use',
        message: 'URL personalizada ya en uso'
      },
      {
        code: 'api.users.user_account_not_validated',
        message: 'La cuenta no ha sido validada'
      },
      {
        code: 'api.users.user_account_no_longer_exists',
        message: 'La cuenta no existe'
      },
      {
        code: 'api.users.user_account_disabled',
        message: 'La cuenta está deshabilitada'
      },
      {
        code: 'api.auth.bad_credentials',
        message: 'El usuario o la contraseña no son validos'
      },
      {
        code: 'password_compromised_publicly',
        message: 'Esta contraseña no es suficientemente compleja'
      }
    ]
  }
}
