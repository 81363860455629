import { HTTP } from '../services/http'
import LanguageLevelFactory from '../domain/services/LanguageLevelFactory'

const URL_LANGUAGES = 'api/users/me/technician/languages'
const headers = {
  'Content-Type': 'application/json'
}

export function createLanguageApi (createLanguageData) {
  return HTTP.post(URL_LANGUAGES, createLanguageData, { headers: headers })
    .then(response => {
      return LanguageLevelFactory.getLanguageLevel(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateLanguageApi (editableLanguage) {
  const languageId = editableLanguage.id
  delete editableLanguage.id
  return HTTP.put(URL_LANGUAGES + '/' + languageId, editableLanguage, { headers: headers })
    .then(response => {
      editableLanguage.id = languageId
      return LanguageLevelFactory.getLanguageLevel(response.data)
    })
}

export function deleteLanguageApi (languageId) {
  return HTTP.delete(URL_LANGUAGES + '/' + languageId)
    .then(() => {})
}
