import BookingSearchItemFactory from '../domain/services/BookingSearchItemFactory'
import BookingFactory from '../domain/services/BookingFactory'
import BookingPositionFactory from '../domain/services/BookingPositionFactory'
import BookingContactedTechnicianFactory from '../domain/services/BookingContactedTechnicianFactory'
import BookingDeleteReasonFactory from '../domain/services/BookingDeleteReasonFactory'
import { HTTP } from '../services/http'
import TechnicianBookingPositionSearchItemFactory from '@/domain/services/TechnicianBookingPositionSearchItemFactory'

const URL_BOOKING = '/api/users/me/booking'
const URL_UPDATE_BOOKING = '/api/users/me/booking/{id}'
const URL_BOOKING_EVENT_TYPES = '/api/booking/event-types'
const URL_BOOKING_DOCS = '/api/booking/docs'
const URL_BOOKING_BY_ID = '/api/users/me/booking/'
const URL_CREATE_BOOKING = '/api/users/me/booking'
const URL_CREATE_BOOKING_POSITION = '/api/users/me/booking/position'
const URL_CREATE_JOBANDTALENT_BOOKING_POSITION = '/api/users/me/booking/jobandtalent-position'
const URL_UPDATE_BOOKING_POSITION = '/api/users/me/booking/{bookingId}/position/{bookingPositionId}'
const URL_UPDATE_JOBANDTALENT_BOOKING_POSITION = '/api/users/me/booking/{bookingId}/jobandtalent-position/{bookingPositionId}'
const URL_BOOKING_BROADCAST = '/api/users/me/booking/{bookingId}/broadcast'
const URL_CONTACTED_TECHNICIAN_FROM_BUSINESS = '/api/users/me/booking/{bookingId}/position/{bookingPositionId}/contactedTechnician'
const URL_TOTAL_CONTACTED_TECHNICIAN_FROM_BUSINESS = '/api/users/me/booking/{bookingId}/position/{bookingPositionId}/totalContactedTechnician'
const URL_UPDATE_TECHNICIAN_FROM_BUSINESS = '/api/users/me/booking/{bookingId}/position/{bookingPositionId}/contactedTechnician/{bookingContactedTechnicianId}'
const URL_BOOKING_GET_DELETE_REASONS = '/api/booking/booking-position/deleted-reasons'
const URL_BOOKING_ADD_DELETE_REASONS = '/api/users/me/booking/{bookingId}/position/{bookingPositionId}/deleted-reasons'
const URL_BOOKING_POSITIONS = '/api/users/me/position'
const URL_UPDATE_CONTACTED_TECHNICIAN_FROM_TECHNICIAN = '/api/users/me/contactedTechnician/{bookingPositionId}'
const URL_GET_CONTACTED_TECHNICIAN_FROM_TECHNICIAN = '/api/users/me/contactedTechnician'

const headers = {
  'Content-Type': 'application/json'
}

export function createBookingApi (bookingData) {
  return HTTP.post(URL_CREATE_BOOKING, bookingData, { headers: headers })
    .then(res => BookingFactory.getBooking(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function createBookingPositionApi (bookingData) {
  return HTTP.post(URL_CREATE_BOOKING_POSITION, bookingData, { headers: headers })
    .then(res => BookingPositionFactory.getBookingPosition(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function createJobandTalentBookingPositionApi (bookingData) {
  return HTTP.post(URL_CREATE_JOBANDTALENT_BOOKING_POSITION, bookingData, { headers: headers })
    .then(res => BookingPositionFactory.getBookingPosition(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateBookingPositionApi (bookingPositionData) {
  const url = URL_UPDATE_BOOKING_POSITION
    .replace('{bookingId}', bookingPositionData.bookingId)
    .replace('{bookingPositionId}', bookingPositionData.id)

  delete bookingPositionData.bookingId

  return HTTP.put(url, bookingPositionData, { headers: headers })
    .then(res => BookingPositionFactory.getBookingPosition(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateJobandTalentBookingPositionApi (bookingPositionData) {
  const url = URL_UPDATE_JOBANDTALENT_BOOKING_POSITION
    .replace('{bookingId}', bookingPositionData.bookingId)
    .replace('{bookingPositionId}', bookingPositionData.id)

  delete bookingPositionData.bookingId

  return HTTP.put(url, bookingPositionData, { headers: headers })
    .then(res => BookingPositionFactory.getBookingPosition(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateBookingApi (data) {
  const url = URL_UPDATE_BOOKING.replace('{id}', data.id)

  delete data.id

  return HTTP.put(url, data, { headers: headers })
    .then(res => BookingFactory.getBooking(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingEventTypes () {
  return HTTP.get(URL_BOOKING_EVENT_TYPES)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingDocs () {
  return HTTP.get(URL_BOOKING_DOCS)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingById (bookingId) {
  return HTTP.get(URL_BOOKING_BY_ID + bookingId)
    .then(res => {
      return BookingFactory.getBooking(res.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingOpened () {
  return HTTP.get(URL_BOOKING + '?status=opened')
    .then(res => {
      return res.data.collection.map(
        booking => {
          return BookingSearchItemFactory.getBookingSearchItem(booking)
        }
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingClosed () {
  return HTTP.get(URL_BOOKING + '?status=closed')
    .then(res => {
      return res.data.collection.map(booking => {
        return BookingSearchItemFactory.getBookingSearchItem(booking)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingExpired () {
  return HTTP.get(URL_BOOKING + '?status=expired')
    .then(res => {
      return res.data.collection.map(booking => {
        return BookingSearchItemFactory.getBookingSearchItem(booking)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function broadcastBookingApi (bookingData) {
  const url = URL_BOOKING_BROADCAST.replace('{bookingId}', bookingData.booking.id)
  const data = {
    positions: bookingData.positions
  }
  return HTTP.post(url, data, { headers: headers })
    .then(res => BookingFactory.getBooking(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function contactedTechnicianFromBusinessApi (data) {
  const url = URL_CONTACTED_TECHNICIAN_FROM_BUSINESS
    .replace('{bookingId}', data.bookingId)
    .replace('{bookingPositionId}', data.bookingPositionId)

  return HTTP.get(url)
    .then(res => {
      return res.data.collection.map(contactedTechnician => {
        return BookingContactedTechnicianFactory
          .getBookingContactedTechnician(contactedTechnician)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function totalContactedTechnicianFromBusinessApi (data) {
  const url = URL_TOTAL_CONTACTED_TECHNICIAN_FROM_BUSINESS
    .replace('{bookingId}', data.bookingId)
    .replace('{bookingPositionId}', data.bookingPositionId)

  return HTTP.get(url)
    .then(res => {
      return res.data.totalContactedTechnician
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateContactedTechnicianFromBusinessApi (data) {
  const url = URL_UPDATE_TECHNICIAN_FROM_BUSINESS
    .replace('{bookingId}', data.bookingId)
    .replace('{bookingPositionId}', data.bookingPositionId)
    .replace('{bookingContactedTechnicianId}', data.bookingContactedTechnicianId)

  const updateData = { businessAccepted: data.businessAccepted }
  return HTTP.put(url, updateData, { headers: headers })
    .then(res => {
      return BookingContactedTechnicianFactory
        .getBookingContactedTechnician(res.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getBookingDeleteReasonsApi () {
  return HTTP.get(URL_BOOKING_GET_DELETE_REASONS)
    .then(res => {
      return res.data.collection.map(bookingDeleteReason => {
        return BookingDeleteReasonFactory
          .getBookingDeleteReason(bookingDeleteReason)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getPositionInboxApi () {
  return HTTP.get(URL_BOOKING_POSITIONS + '?status=inbox')
    .then(res => {
      return res.data.collection.map(
        bookingPosition => {
          return TechnicianBookingPositionSearchItemFactory
            .get(bookingPosition)
        }
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function addDeleteReasonsApi (data) {
  const url = URL_BOOKING_ADD_DELETE_REASONS
    .replace('{bookingId}', data.bookingId)
    .replace('{bookingPositionId}', data.bookingPositionId)

  delete data.bookingId
  delete data.bookingPositionId

  return HTTP.post(url, data, { headers: headers })
    .then(res => BookingPositionFactory.getBookingPosition(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getPositionPendingApi () {
  return HTTP.get(URL_BOOKING_POSITIONS + '?status=pending')
    .then(res => {
      return res.data.collection.map(
        bookingPosition => {
          return TechnicianBookingPositionSearchItemFactory
            .get(bookingPosition)
        }
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getPositionAcceptedApi () {
  return HTTP.get(URL_BOOKING_POSITIONS + '?status=accepted')
    .then(res => {
      return res.data.collection.map(
        bookingPosition => {
          return TechnicianBookingPositionSearchItemFactory
            .get(bookingPosition)
        }
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getPositionRejectedApi () {
  return HTTP.get(URL_BOOKING_POSITIONS + '?status=rejected')
    .then(res => {
      return res.data.collection.map(
        bookingPosition => {
          return TechnicianBookingPositionSearchItemFactory
            .get(bookingPosition)
        }
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateContactedTechnicianAsTechnicianApi (contactedTechnicianData) {
  const url = URL_UPDATE_CONTACTED_TECHNICIAN_FROM_TECHNICIAN
    .replace('{bookingPositionId}', contactedTechnicianData.bookingPositionId)

  const data = {
    technicianAccepted: contactedTechnicianData.technicianAccepted,
    technicianSawIt: contactedTechnicianData.technicianSawIt
  }

  return HTTP.put(url, data, { headers: headers })
    .then(res => {
      return BookingContactedTechnicianFactory
        .getBookingContactedTechnician(res.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getContactedTechnicianAsTechnicianApi () {
  const url = URL_GET_CONTACTED_TECHNICIAN_FROM_TECHNICIAN + '?status=unread'

  return HTTP.get(url)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}
