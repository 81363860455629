import EditableUserTechnician from '../models/EditableUserTechnician'

class EditableUserTechnicianFactory {
  getEditableUserTechnician (UserData) {
    const userTechnician = {
      id: UserData.id,
      birthday: UserData.birthday,
      address: UserData.address || '',
      postalCode: UserData.postalCode,
      phoneNumber: UserData.phone,
      gender: UserData.gender,
      freelancer: UserData.freelancer,
      description: UserData.description,
      nickname: UserData.nickname,
      linkedInProfile: UserData.linkedInProfile,
      location: UserData.location,
      contactEmail: UserData.contactEmail,
      avatarImage: UserData.avatarImage,
      avatarExtension: UserData.avatarExtension,
      coverImage: UserData.coverImage,
      coverExtension: UserData.coverExtension,
      sectors: UserData.sectors,
      skills: UserData.skills,
      roles: UserData.roles,
      available: UserData.available
    }
    return new EditableUserTechnician(userTechnician)
  }
}

export default new EditableUserTechnicianFactory()
