export default class LanguageLevel {
  constructor (languageLevelData) {
    this.id = languageLevelData.id
    this.language = languageLevelData.language
    this.level = languageLevelData.level
  }

  update (language) {
    Object.assign(this, language)
  }
}
