import Certification from '../models/Certification'

class CertificationFactory {
  getCertification (CertificationData) {
    const certification = {
      id: CertificationData.id,
      centerName: CertificationData.centerName,
      name: CertificationData.name,
      observations: CertificationData.observations,
      startAtYear: CertificationData.startAtYear,
      startAtMonth: CertificationData.startAtMonth,
      finishedAtYear: CertificationData.finishedAtYear,
      finishedAtMonth: CertificationData.finishedAtMonth,
      expirationYear: CertificationData.expirationYear,
      expirationMonth: CertificationData.expirationMonth
    }
    return new Certification(certification)
  }
}

export default new CertificationFactory()
