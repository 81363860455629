export default class HardwareBooking {
  constructor (bookingData) {
    this.id = bookingData.id
    this.name = bookingData.name
    this.opened = bookingData.opened
    this.createdBy = bookingData.createdBy
    this.createdAt = bookingData.createdAt
    this.code = bookingData.code
    this.location = bookingData.location
    this.eventStartAt = bookingData.eventStartAt
    this.eventEndAt = bookingData.eventEndAt
    this.eventType = bookingData.eventType
    this.broadcasted = bookingData.broadcasted
    this.positions = bookingData.positions
    this.pricePerDay = bookingData.pricePerDay
  }

  get getId () {
    return this.id
  }

  get getOpened () {
    return this.opened
  }

  updateOpened (opened) {
    this.opened = opened
  }

  get getName () {
    return this.name
  }

  get getCode () {
    return this.code
  }

  get getCreatedBy () {
    return this.createdBy
  }

  get getCreatedAt () {
    return new Date(this.createdAt.replace(' ', 'T'))
  }

  get getEventType () {
    return this.eventType
  }

  get getLocation () {
    return this.location
  }

  get getCompleteLocation () {
    if (!this.location) {
      return ''
    }
    return this.location.name + ', ' +
      this.location.administrative + ', ' +
      this.location.country
  }

  get getEventStartAt () {
    if (!this.eventStartAt) {
      return
    }
    return new Date(this.eventStartAt.replace(' ', 'T'))
  }

  get getEventEndAt () {
    if (!this.eventEndAt) {
      return
    }
    return new Date(this.eventEndAt.replace(' ', 'T'))
  }

  get getBroadcasted () {
    return this.broadcasted
  }

  get getPositions () {
    return this.positions
  }

  get hasPositions () {
    return this.positions.length > 0
  }

  get getEventDate () {
    const startDate = this.getEventStartAt
    const endDate = this.getEventEndAt
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  get getShortLocation () {
    return this.location.name + ' (' + this.location.administrative + ')'
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getLocalizedMonthNameByMonth (month) {
    return ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'][month]
  }

  get getPrice () {
    return String(this.pricePerDay * this.getDayDifference()).replace('.', ',')
  }

  getDayDifference () {
    const diff = new Date(this.eventEndAt) - new Date(this.eventStartAt)
    return 1 + Math.floor(diff / 3600000 / 24)
  }
}
