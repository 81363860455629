import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import actions from '@/store/actions'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import hardwareModule from './modules/hardware'
import projectModule from './modules/project'
import employeeModule from './modules/employee'
import technicianBookingModule from './modules/technician-booking'
import technicianPositionModule from './modules/technician-position'
import hardwareBookingModule from './modules/hardware-booking'
import hardwarePositionModule from './modules/hardware-position'
import hardwareBrandModule from './modules/hardware-brand'
import productTypeModule from './modules/product-type'
import userModule from './modules/user'
import venueModule from './modules/venues'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    hardware: hardwareModule,
    project: projectModule,
    employee: employeeModule,
    venues: venueModule,
    technicianBooking: technicianBookingModule,
    technicianPosition: technicianPositionModule,
    hardwareBooking: hardwareBookingModule,
    hardwarePosition: hardwarePositionModule,
    hardwareBrand: hardwareBrandModule,
    productType: productTypeModule,
    user: userModule
  }
})
