export default class HardwareBookingPosition {
  constructor (bookingPositionData) {
    this.id = bookingPositionData.id
    this.quantity = bookingPositionData.quantity
    this.opened = bookingPositionData.opened
    this.title = bookingPositionData.title
    this.annotation = bookingPositionData.annotation
    this.startDate = bookingPositionData.startDate
    this.endDate = bookingPositionData.endDate
    this.brand = bookingPositionData.brand
    this.type = bookingPositionData.type
  }

  get getId () {
    return this.id
  }

  get getQuantity () {
    return String(this.quantity).replace('.', ',')
  }

  getDayDifference () {
    const diff = new Date(this.endDate) - new Date(this.startDate)
    return 1 + Math.floor(diff / 3600000 / 24)
  }

  get getOpened () {
    return this.opened
  }

  get getTitle () {
    return this.title
  }

  get getAnnotation () {
    return this.annotation
  }

  get getBrand () {
    return this.brand
  }

  get getProductType () {
    return this.type
  }

  get getStartDate () {
    if (!this.startDate) {
      return null
    }
    return new Date(this.startDate.replace(' ', 'T'))
  }

  get getEndDate () {
    if (!this.endDate) {
      return null
    }
    return new Date(this.endDate.replace(' ', 'T'))
  }

  get getEventDate () {
    const startDate = this.getStartDate
    const endDate = this.getEndDate
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getLocalizedMonthNameByMonth (month) {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ][month]
  }

  get getOnlyTime () {
    const startAt = this.getStartDate
    const endAt = this.getEndDate
    return this.lpad(startAt.getHours()) + ':' +
      this.lpad(startAt.getMinutes()) + ' - ' +
      this.lpad(endAt.getHours()) + ':' +
      this.lpad(endAt.getMinutes())
  }

  lpad (str) {
    return str.toString().padStart(2, 0)
  }
}
