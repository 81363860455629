import ProjectFactory from '../domain/services/ProjectFactory'
import { HTTP } from '../services/http'

const URL_GET_PROJECTS = 'api/users/me/business/projects'
const URL_GET_PROJECT_BY_ID = 'api/users/me/business/projects/{id}'
const URL_CREATE_PROJECT = 'api/users/me/business/projects'
const URL_UPDATE_PROJECT = 'api/users/me/business/projects/{id}'
const URL_DELETE_PROJECT = 'api/users/me/business/projects/{id}'

const headers = {
  'Content-Type': 'application/json'
}

export function getProjectsApi () {
  return HTTP.get(URL_GET_PROJECTS)
    .then(response => response.data.collection.map(project => ProjectFactory.get(project)))
}

export function getProjectByIdApi (id) {
  const url = URL_GET_PROJECT_BY_ID.replace('{id}', id)
  return HTTP.get(url)
    .then(response => ProjectFactory.get(response.data))
}

export function createProjectApi (data) {
  return HTTP.post(URL_CREATE_PROJECT, data, { headers })
    .then(response => ProjectFactory.get(response.data))
}

export function updateProjectApi (data) {
  const project = Object.assign({}, data)
  const url = URL_UPDATE_PROJECT.replace('{id}', project.id)
  delete project.id
  return HTTP.put(url, project, { headers })
    .then(response => ProjectFactory.get(response.data))
}

export function deleteProjectApi (id) {
  const url = URL_DELETE_PROJECT.replace('{id}', id)
  return HTTP.delete(url)
    .then(response => response)
}
