import {
  fetchAll,
  fetchOneById,
  createRequest,
  updateRequest,
  deleteRequest,
  fetchAllByTechnician,
  fetchAllExternalTechnician,
  fetchOOneByIdTechnician,
  fetchAllExternalHardware
} from '@/api/business/ProjectApi'

export default {
  getAll ({ commit }) {
    return fetchAll()
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getAllByTechnician ({ commit }) {
    return fetchAllByTechnician()
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, id) {
    return fetchOneById(id)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneByIdTechnician ({ commit }, id) {
    return fetchOOneByIdTechnician(id)
      .then(response => {
        commit('setCurrent', response)
      })
  },
  create ({ commit }, payload) {
    delete payload.id
    return createRequest(payload)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  update ({ commit }, payload) {
    return updateRequest(mapUpdateRequest(payload))
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  delete ({ commit }, id) {
    return deleteRequest(id)
      .then(response => {
        commit('remove', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  listExternalTechnician ({ commit }, id) {
    return fetchAllExternalTechnician(id)
      .then(response => {
        commit('setAllExternalEmployees', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  listExternalHardware ({ commit }, id) {
    return fetchAllExternalHardware(id)
      .then(response => {
        commit('setAllExternalHardware', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

function mapUpdateRequest (project) {
  if (!project.deletedTeams) {
    project = { ...project, deletedTeams: [] }
  }
  if (!project.deletedDocs) {
    project = { ...project, deletedDocs: [] }
  }
  return {
    id: project.id,
    name: project.name,
    venue: project.venue,
    venueExtraName: project.venueExtraName,
    startAt: project.startAt,
    endAt: project.endAt,
    managers: project.managers.map(e => e.id),
    nonManagers: project.nonManagers.map(e => e.id),
    hardware: project.hardware.map(e => e.id),
    teams: project.teams
      .filter(t => !t.id)
      .map(t => {
        return {
          name: t.name,
          members: t.members.map(e => e.id),
          groupLink: t.groupLink !== undefined ? t.groupLink : null
        }
      }),
    updatedTeams: project.teams
      .filter(t => t.id)
      .map(t => {
        return {
          id: t.id,
          name: t.name,
          members: t.members.map(e => e.id),
          groupLink: t.groupLink !== undefined ? t.groupLink : null
        }
      }),
    deletedTeams: project.deletedTeams,
    docs: project.docs.filter(doc => !doc.id),
    deletedDocs: project.deletedDocs
  }
}
