import { fetchAll, fetchOneById } from '@/api/business/VenueApi'

export default {
  getAll ({ commit }) {
    return fetchAll()
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, id) {
    return fetchOneById(id)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
