import LanguageFilter from '../../models/filters/LanguageFilter'

class LanguageFilterFactory {
  getLanguageFilter (filterData) {
    const auth = {
      id: filterData.id,
      name: filterData.name
    }

    return new LanguageFilter(auth)
  }
}

export default new LanguageFilterFactory()
