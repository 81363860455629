import JobCategoryFactory from '../domain/services/JobCategoryFactory'
import JobFactory from '../domain/services/JobFactory'
import { HTTP } from '../services/http'

const URL_GET_JOB_CATEGORIES = 'api/job-categories'

const URL_CREATE_JOB = 'api/users/me/jobs'
const URL_UPDATE_JOB = 'api/users/me/jobs/{id}'
const URL_DELETE_JOB = 'api/users/me/jobs/{id}'

const headers = {
  'Content-Type': 'application/json'
}

export function getJobCategoriesApi () {
  return HTTP.get(URL_GET_JOB_CATEGORIES)
    .then(response => response.data.collection.map(jobCategory => JobCategoryFactory.get(jobCategory)))
}

export function createJobApi (data) {
  return HTTP.post(URL_CREATE_JOB, data, { headers })
    .then(response => JobFactory.get(response.data))
}

export function updateJobApi (data) {
  const url = URL_UPDATE_JOB.replace('{id}', data.id)
  return HTTP.put(url, data, { headers })
    .then(response => JobFactory.get(response.data))
}

export function deleteJobApi (id) {
  const url = URL_DELETE_JOB.replace('{id}', id)
  return HTTP.delete(url)
    .then(response => response)
}
