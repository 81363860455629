import {
  fetchAll,
  fetchOneById,
  createRequest,
  updateRequest,
  deleteRequest,
  fetchTotalContactedHardwareFromBusiness,
  fetchContactedHardwareFromBusiness,
  updateHardwareBookingContactedFromBusiness,
  updateHardwareBookingContactedFromTechnician,
  getHardwarePositionsInboxApi,
  getHardwarePositionsPendingApi,
  getHardwarePositionsRejectedApi,
  getHardwarePositionsAcceptedApi,
  getContactedHardwareTechnicianApi,
  getDeleteReasonApi,
  addDeleteReasonsApi

} from '@/api/business/HardwarePositionApi'
import HardwareBookingPositionFactory from '@/domain/services/HardwareBookingPositionFactory'

export default {
  getAll ({ commit }, bookingId) {
    return fetchAll(bookingId)
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, payload) {
    return fetchOneById(payload.bookingId, payload.bookingPositionId)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  create ({ commit }, payload) {
    return createRequest(payload.bookingId, payload.data)
      .then(response => {
        commit('add', response)
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  update ({ commit }, payload) {
    return updateRequest(payload.bookingId, payload.data)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  delete ({ commit }, payload) {
    return deleteRequest(payload.hardwareBookingId, payload.hardwareBookingPositionId, payload)
      .then(response => {
        commit('remove', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setEmpty ({ commit, rootGetters }) {
    commit('setEmpty', getEmpty(rootGetters.loggedUser))
  },
  getTotalContactedHardwareFromBusiness ({ commit }, payload) {
    return fetchTotalContactedHardwareFromBusiness(payload.bookingId, payload.bookingPositionId)
  },
  getContactedHardwareFromBusiness ({ commit }, payload) {
    return fetchContactedHardwareFromBusiness(payload.bookingId, payload.bookingPositionId)
  },
  updateHardwareBookingContactedFromBusiness ({ commit }, payload) {
    return updateHardwareBookingContactedFromBusiness(payload)
  },
  getHardwarePositionInbox: async (context) => {
    const positionInboxApi = getHardwarePositionsInboxApi()
    return positionInboxApi.then(res => res)
  },
  getHardwarePositionPending: async (context) => {
    const positionInboxApi = getHardwarePositionsPendingApi()
    return positionInboxApi.then(res => res)
  },
  getHardwarePositionAccepted: async (context) => {
    const positionInboxApi = getHardwarePositionsAcceptedApi()
    return positionInboxApi.then(res => res)
  },
  getHardwarePositionsRejected: async (context) => {
    const positionInboxApi = getHardwarePositionsRejectedApi()
    return positionInboxApi.then(res => res)
  },
  getContactedHardwareTechnician (context) {
    getContactedHardwareTechnicianApi()
      .then(response => context.commit(
        'getHardwareBookingNotifications',
        response.collection
      ))
  },
  updateHardwareBookingContactedFromTechnician ({ commit }, payload) {
    return updateHardwareBookingContactedFromTechnician(payload)
  },
  getDeleteReasons (context) {
    return getDeleteReasonApi()
  },
  addDeleteReasons (context, addDeleteReasonsData) {
    return addDeleteReasonsApi(addDeleteReasonsData)
  }
}

function getEmpty (loggedUser) {
  return HardwareBookingPositionFactory.get({
    bookingId: null,
    pricePerDay: null,
    title: null,
    annotations: null,
    startDate: null,
    endDate: null,
    brand: null,
    productType: null
  })
}
