export default class BookingSearchItem {
  constructor (bookingData) {
    this.id = bookingData.id
    this.name = bookingData.name
    this.code = bookingData.code
    this.opened = bookingData.opened
    this.broadcasted = bookingData.broadcasted
    this.location = bookingData.location
    this.eventStartAt = bookingData.eventStartAt
    this.eventEndAt = bookingData.eventEndAt
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getCode () {
    return this.code
  }

  get getOpened () {
    return this.opened
  }

  get getBroadcasted () {
    return this.broadcasted
  }

  get getLocation () {
    return this.location
  }

  get getEventStartAt () {
    return new Date(this.eventStartAt.replace(' ', 'T'))
  }

  get getEventEndAt () {
    return new Date(this.eventEndAt.replace(' ', 'T'))
  }

  get getEventDate () {
    const startDate = this.getEventStartAt
    const endDate = this.getEventEndAt
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  get getShortLocation () {
    return this.location.name + ' (' + this.location.administrative + ')'
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getLocalizedMonthNameByMonth (month) {
    return ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'][month]
  }
}
