export default class UserBusiness {
  constructor (userBusinessData) {
    this.address = userBusinessData.address
    this.avatar = userBusinessData.avatar
    this.cif = userBusinessData.cif
    this.location = userBusinessData.location
    this.facebookProfile = userBusinessData.facebookProfile
    this.instagramProfile = userBusinessData.instagramProfile
    this.linkedInProfile = userBusinessData.linkedInProfile
    this.name = userBusinessData.name
    this.phoneNumber = userBusinessData.phoneNumber
    this.profileCover = userBusinessData.profileCover
    this.twitterProfile = userBusinessData.twitterProfile
    this.web = userBusinessData.web
    this.zipcode = userBusinessData.zipcode
    this.sectors = userBusinessData.sectors
    this.bookingCreator = userBusinessData.bookingCreator
  }

  get getIsEmpty () {
    return typeof this === 'undefined'
  }

  get getFormattedLocation () {
    if (!this.getLocationName) {
      return null
    }
    return this.getLocationName + ', ' +
      this.getLocationAdministrative + ', ' +
      this.getLocationCountry
  }

  get getLocationName () {
    return this.location.name
  }

  get getLocationAdministrative () {
    return this.location.administrative
  }

  get getLocationCountry () {
    return this.location.country
  }

  get getAddress () {
    return this.address
  }

  get hasContactEmail () {
    return false
  }

  get hasPhoneNumber () {
    return this.phoneNumber.number !== '' &&
      this.phoneNumber.number !== null &&
      this.phoneNumber.number !== 'null' &&
      this.phoneNumber.number !== undefined
  }

  get getPrefix () {
    if (this.hasPhoneNumber) {
      return this.phoneNumber.prefix
    }
  }

  get getPhoneNumber () {
    if (this.hasPhoneNumber) {
      return this.phoneNumber.number
    }
  }

  get getCompletePhoneNumber () {
    if (this.hasPhoneNumber) {
      return this.phoneNumber.prefix + ' ' + this.phoneNumber.number
    }
  }

  get hasLinkedInProfile () {
    return this.linkedInProfile !== null && this.linkedInProfile !== '' && this.linkedInProfile !== undefined
  }

  get getlinkedInProfile () {
    return this.linkedInProfile
  }

  get hasLocation () {
    return !this.getValueIsEmpty(this.location)
  }

  getValueIsEmpty (value) {
    return value === '' ||
      value === null ||
      value === 'null' ||
      value === undefined
  }

  get getSectors () {
    return this.sectors
  }

  updateSectors (sectors) {
    this.sectors = sectors
  }

  get hasAvatar () {
    return this.avatar !== null && this.avatar !== []
  }

  get getAvatar () {
    return this.avatar
  }

  get hasCover () {
    return this.profileCover !== null && this.profileCover !== []
  }

  get getCover () {
    return this.profileCover
  }

  get isBookingCreator () {
    return this.bookingCreator
  }
}
