import {
  fetchAll,
  fetchOneById,
  createRequest,
  updateRequest,
  deleteRequest,
  fetchTotalContactedTechnicianFromBusiness,
  fetchContactedTechnicianFromBusiness,
  updateTechnicianBookingContactedFromBusiness,
  addDeleteReasonsApi,
  getDeleteReasonApi
} from '@/api/business/TechnicianPositionApi'
import TechnicianBookingPositionFactory from '@/domain/services/TechnicianBookingPositionFactory'
import { HANDLER_NATIVE } from '@/domain/models/TechnicianBookingPosition'

export default {
  getAll ({ commit }, bookingId) {
    return fetchAll(bookingId)
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, payload) {
    return fetchOneById(payload.bookingId, payload.bookingPositionId)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  create ({ commit }, payload) {
    return createRequest(payload.bookingId, payload.data)
      .then(response => {
        commit('add', response)
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  update ({ commit }, payload) {
    return updateRequest(payload.bookingId, payload.data)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  delete ({ commit }, payload) {
    return deleteRequest(payload.technicianBookingId, payload.technicianBookingPositionId, payload)
      .then(response => {
        commit('remove', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setEmpty ({ commit, rootGetters }) {
    commit('setEmpty', getEmpty(rootGetters.loggedUser))
  },
  getTotalContactedTechnicianFromBusiness ({ commit }, payload) {
    return fetchTotalContactedTechnicianFromBusiness(payload.bookingId, payload.bookingPositionId)
  },
  getContactedTechnicianFromBusiness ({ commit }, payload) {
    return fetchContactedTechnicianFromBusiness(payload.bookingId, payload.bookingPositionId)
  },
  updateTechnicianBookingContactedFromBusiness ({ commit }, payload) {
    return updateTechnicianBookingContactedFromBusiness(payload)
  },
  addDeleteReasons (context, addDeleteReasonsData) {
    return addDeleteReasonsApi(addDeleteReasonsData)
  },
  getDeleteReasons (context) {
    return getDeleteReasonApi()
  }
}

function getEmpty (loggedUser) {
  return TechnicianBookingPositionFactory.get({
    bookingId: null,
    roles: [],
    skills: [],
    drivingLicenses: [],
    pricePerDay: null,
    freelance: null,
    title: null,
    annotation: null,
    startDate: null,
    endDate: null,
    handler: HANDLER_NATIVE
  })
}
