export default class HardwareBookingPositionSearchItem {
  constructor (bookingPositionData) {
    this.id = bookingPositionData.id
    this.quantity = bookingPositionData.quantity
    this.opened = bookingPositionData.opened
    this.title = bookingPositionData.title
    this.annotations = bookingPositionData.annotations
    this.startDate = bookingPositionData.startDate
    this.endDate = bookingPositionData.endDate
    this.brand = bookingPositionData.brand
    this.productType = bookingPositionData.productType
    this.location = bookingPositionData.hardwareBooking.location
    this.createdAt = bookingPositionData.hardwareBooking.createdAt
    this.eventStartAt = new Date(bookingPositionData.hardwareBooking.eventStartAt.replace(' ', 'T'))
    this.eventEndAt = new Date(bookingPositionData.hardwareBooking.eventEndAt.replace(' ', 'T'))

    this.businessName = bookingPositionData.hardwareBooking.business.name
    this.businessAvatar = bookingPositionData.hardwareBooking.business.avatar
  }

  get getId () {
    return this.id
  }

  get getQuantity () {
    return String(this.quantity).replace('.', ',')
  }

  getDayDifference () {
    const diff = new Date(this.endDate) - new Date(this.startDate)
    return 1 + Math.floor(diff / 3600000 / 24)
  }

  get getOpened () {
    return this.opened
  }

  get getTitle () {
    return this.title
  }

  get getAnnotations () {
    return this.annotations
  }

  get getBrand () {
    return this.brand
  }

  get getProductType () {
    return this.productType
  }

  get getStartDate () {
    if (!this.startDate) {
      return null
    }
    return new Date(this.startDate.replace(' ', 'T'))
  }

  get getEndDate () {
    if (!this.endDate) {
      return null
    }
    return new Date(this.endDate.replace(' ', 'T'))
  }

  get getCreatedAt () {
    return new Date(this.createdAt.replace(' ', 'T'))
  }

  get getEventDate () {
    const startDate = this.getStartDate
    const endDate = this.getEndDate
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getLocalizedMonthNameByMonth (month) {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ][month]
  }

  get getGoogleMapsUrl () {
    const address = `${this.location.name || ''}, ${this.location.administrative || ''}, ${this.location.country || ''}, ${this.location.county || ''}`

    const encodedAddress = encodeURI(address)

    return `https://www.google.com/maps?q=${encodedAddress}&output=embed`
  }

  get getOnlyTime () {
    const startAt = this.getStartDate
    const endAt = this.getEndDate
    return this.lpad(startAt.getHours()) + ':' +
      this.lpad(startAt.getMinutes()) + ' - ' +
      this.lpad(endAt.getHours()) + ':' +
      this.lpad(endAt.getMinutes())
  }

  get getSimpleCreatedAt () {
    const hourDiff = (new Date() - this.getCreatedAt) / 1000 / 3600
    if (hourDiff < 24) {
      return this.simpleHours(hourDiff)
    }
    if (hourDiff < 720) {
      return this.simpleDays(hourDiff)
    }

    return 'Hace meses'
  }

  get getBusinessName () {
    return this.businessName
  }

  get getBusinessAvatar () {
    return this.businessAvatar
  }

  simpleHours (hours) {
    if (hours < 2) {
      return 'Hace 1 hora'
    }

    return 'Hace ' + Math.round(hours) + ' horas'
  }

  simpleDays (hours) {
    if (hours < 48) {
      return 'Hace 1 día'
    }

    return 'Hace ' + Math.round(hours / 24) + ' días'
  }

  lpad (str) {
    return str.toString().padStart(2, 0)
  }
}
