import { fetchAll } from '@/api/HardwareBrandApi'

export default {
  getAll ({ commit }) {
    return fetchAll()
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
