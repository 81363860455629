import { HTTP } from '@/services/http'
import TechnicianBookingFactory from '@/domain/services/TechnicianBookingFactory'

const URL = 'api/users/me/business/projects/'
const MIDDLE_URL = '/technician-booking'

const headers = {
  'Content-Type': 'application/json'
}

export function fetchAll (projectId) {
  const url = URL + projectId + MIDDLE_URL
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => TechnicianBookingFactory.get(item)))
}

export function fetchOneById (projectId, id) {
  const url = URL + projectId + MIDDLE_URL + '/' + id
  return HTTP.get(url)
    .then(response => TechnicianBookingFactory.get(response.data))
}

export function createRequest (projectId, data) {
  const url = URL + projectId + MIDDLE_URL
  return HTTP.post(url, data, { headers })
    .then(response => TechnicianBookingFactory.get(response.data))
}

export function updateRequest (projectId, data) {
  const entityToUpdate = Object.assign({}, data)
  const url = URL + projectId + MIDDLE_URL + '/' + entityToUpdate.id
  delete entityToUpdate.id
  return HTTP.put(url, entityToUpdate, { headers })
    .then(response => TechnicianBookingFactory.get(response.data))
}

export function deleteRequest (projectId, id) {
  const url = URL + projectId + MIDDLE_URL + '/' + id
  return HTTP.delete(url)
    .then(response => response)
}

export function broadcastBookingRequest (projectId, booking) {
  const url = URL + projectId + MIDDLE_URL + '/' + booking.id + '/broadcast'
  const positions = {
    positions: booking.positions.map((position) => {
      return { id: position.id, hiredByAvify: position.isHiredByAvify, handler: position.getHandler }
    })
  }
  return HTTP.post(url, positions, { headers: headers })
    .then(response => TechnicianBookingFactory.get(response.data))
    .catch(error => {
      return Promise.reject(error)
    })
}
