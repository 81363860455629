import Vue from 'vue'

import { extend, ValidationProvider, ValidationObserver, setInteractionMode, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

setInteractionMode('passive')

extend('password', value => {
  if (/^(?=.*[A-z])(?=.*\d).{8,}$/.test(value)) {
    return true
  }
  return '8 caracteres mínimo, con al menos una letra y un número'
})

extend('cif', value => {
  if (/^[a-zA-Z]?[0-9]{8}[a-zA-Z]?$/.test(value)) {
    return true
  }
  return 'El formato del NIF no es válido'
})

extend('prefix', value => {
  if (/^\+[0-9]{1,3}$/.test(value)) {
    return true
  }
  return 'El formato del prefijo no es válido (p.ej +34)'
})

extend('todayOn', value => {
  const dateString = value.replace(/(\d{2})\/(\d{2})\/(\d{4}).*$/, '$3-$2-$1')
  const d = new Date(dateString)
  if (d >= (new Date())) {
    return true
  }
  return 'La fecha debería ser mayor que hoy'
})

extend('url', (value) => {
  if (value && /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)) {
    return true
  }

  return 'El formato del campo no es válido'
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

localize('es', es)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
