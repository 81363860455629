import UserTechnician from './UserTechnician'

export default class TechnicianBookingContacted {
  constructor (data) {
    this.id = data.id
    this.user = data.user
    this.userTechnician = new UserTechnician(data.userTechnician)
    this.technicianAccepted = data.technicianAccepted
    this.businessAccepted = data.businessAccepted
    this.favorite = data.favorite
  }

  get getId () {
    return this.id
  }

  get getUserTechnician () {
    return this.userTechnician
  }

  get getTechnicianAccepted () {
    return this.technicianAccepted
  }

  get getBusinessAccepted () {
    return this.businessAccepted
  }

  get getFavorite () {
    return this.favorite
  }
}
