import Booking from '../models/Booking'
import BookingPositionFactory from './BookingPositionFactory'

class BookingFactory {
  getBooking (bookingData) {
    const booking = {
      id: bookingData.id,
      name: bookingData.name,
      opened: bookingData.opened,
      code: bookingData.code,
      createdAt: bookingData.createdAt,
      createdBy: bookingData.createdBy,
      location: bookingData.location,
      eventStartAt: bookingData.eventStartAt,
      eventEndAt: bookingData.eventEndAt,
      eventType: bookingData.eventType,
      catering: bookingData.catering,
      broadcasted: bookingData.broadcasted,
      docs: bookingData.docs,
      docExtra: bookingData.docExtra,
      positions: iterateCreatePositions(bookingData.positions)
    }

    return new Booking(booking)
  }
}

function iterateCreatePositions (positions) {
  return positions.map(position => BookingPositionFactory.getBookingPosition(position))
}

export default new BookingFactory()
