export default {
  all (state) {
    return state.all
  },
  current (state) {
    return state.current
  },
  opened (state) {
    return state.all
      .filter(e => e.opened && isFutureDate(e.eventEndAt))
  },
  closed (state) {
    return state.all
      .filter(e => !e.opened && isFutureDate(e.eventEndAt))
  },
  expired (state) {
    return state.all
      .filter(e => !isFutureDate(e.eventEndAt))
  }
}

function isFutureDate (d) {
  const now = new Date()
  const date = new Date(d)
  return date.getTime() >= now.getTime()
}
