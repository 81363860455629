import UserTechnician from './UserTechnician'

export default class HardwareBookingContacted {
  constructor (data) {
    this.id = data.id
    this.user = data.user
    this.userTechnician = new UserTechnician(data.userTechnician)
    this.hardwareOwnerAccepted = data.hardwareOwnerAccepted
    this.businessAccepted = data.businessAccepted
    this.favorite = data.favorite
  }

  get getId () {
    return this.id
  }

  get getUserTechnician () {
    return this.userTechnician
  }

  get isHardwareOwnerAccepted () {
    return this.hardwareOwnerAccepted
  }

  get isBusinessAccepted () {
    return this.businessAccepted
  }

  get getFavorite () {
    return this.favorite
  }
}
