import { HTTP } from '@/services/http'
import VenueFactory from '@/domain/services/VenueFactory'

const URL = 'api/venues'

export function fetchAll () {
  return HTTP.get(URL)
    .then(response => response.data.collection.map(item => VenueFactory.getVenue(item)))
}

export function fetchOneById (id) {
  const url = 'api/users/me/venues/' + id
  return HTTP.get(url)
    .then(response => VenueFactory.getVenue(response.data))
}
