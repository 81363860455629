import { HTTP } from '@/services/http'
import TechnicianBookingPositionFactory from '@/domain/services/TechnicianBookingPositionFactory'
import TechnicianBookingContactedFactory from '@/domain/services/TechnicianBookingContactedFactory'
import BookingDeleteReasonFactory from '@/domain/services/BookingDeleteReasonFactory'

const URL = 'api/users/me/technician-booking/'
const MIDDLE_URL = '/position'
const URL_BOOKING_ADD_DELETE_REASONS = '/api/users/me/technician-booking/{bookingId}/position/{bookingPositionId}/deleted-reasons'
const URL_BOOKING_GET_DELETE_REASONS = '/api/users/me/technician-booking/deleted-reasons'

const headers = {
  'Content-Type': 'application/json'
}

export function fetchAll (bookingId) {
  const url = URL + bookingId + MIDDLE_URL
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => TechnicianBookingPositionFactory.get(item)))
}

export function fetchOneById (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id
  return HTTP.get(url)
    .then(response => TechnicianBookingPositionFactory.get(response.data))
}

export function createRequest (bookingId, data) {
  const url = URL + bookingId + MIDDLE_URL
  return HTTP.post(url, data, { headers: headers })
    .then(response => TechnicianBookingPositionFactory.get(response.data))
}

export function updateRequest (bookingId, data) {
  const entityToUpdate = Object.assign({}, data)
  const url = URL + bookingId + MIDDLE_URL + '/' + entityToUpdate.id
  delete entityToUpdate.id
  return HTTP.put(url, entityToUpdate, { headers: headers })
    .then(response => TechnicianBookingPositionFactory.get(response.data))
}

export function deleteRequest (bookingId, id, payload) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id
  const data = { bookingPositionDeletedReasonId: payload.bookingDeletedReasonId }
  return HTTP.delete(url, { data: data })
    .then(response => response)
}

export function fetchTotalContactedTechnicianFromBusiness (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id + '/totalContactedTechnician'
  return HTTP.get(url)
    .then(response => {
      return response.data.totalContactedTechnician
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function fetchContactedTechnicianFromBusiness (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id + '/contactedTechnician'
  return HTTP.get(url)
    .then(response => {
      return response.data.collection.map(contactedTechnician => {
        return TechnicianBookingContactedFactory
          .get(contactedTechnician)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateTechnicianBookingContactedFromBusiness (payload) {
  const url = URL + payload.bookingId + MIDDLE_URL + '/' + payload.positionId + '/contactedTechnician' + '/' + payload.contactedId
  const updateData = { businessAccepted: payload.businessAccepted }
  return HTTP.put(url, updateData, { headers: headers })
    .then(response => {
      return TechnicianBookingContactedFactory
        .get(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getDeleteReasonApi () {
  return HTTP.get(URL_BOOKING_GET_DELETE_REASONS)
    .then(res => {
      return res.data.collection.map(bookingDeleteReason => {
        return BookingDeleteReasonFactory
          .getBookingDeleteReason(bookingDeleteReason)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function addDeleteReasonsApi (data) {
  const url = URL_BOOKING_ADD_DELETE_REASONS
    .replace('{bookingId}', data.technicianBookingId)
    .replace('{bookingPositionId}', data.technicianBookingPositionId)

  delete data.technicianBookingId

  return HTTP.post(url, data, { headers: headers })
    .then(res => TechnicianBookingPositionFactory.get(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}
