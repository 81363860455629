import Auth from '../models/Auth'

class AuthFactory {
  getAuth (AuthData) {
    const auth = {
      token: AuthData.token,
      refreshToken: AuthData.refreshToken
    }

    return new Auth(auth)
  }
}

export default new AuthFactory()
