import Profiles from '@/utils/getProfiles'

const ACCESS_TOKEN = 'access_token'
const ACCESS_REFRESH_TOKEN = 'access_refresh_token'

export const getDefaultState = () => ({
  auth: {
    token: null,
    refreshToken: null
  },
  loggedUser: {},
  currentProfile: localStorage.getItem('currentProfile') || Profiles.PROFILE_TECHNICIAN,
  publicUser: {},
  languages: [],
  bookingEventTypes: [],
  bookingDocs: [],
  bookingOpened: [],
  bookingClosed: [],
  bookingExpired: [],
  technicianBookingNotifications: [],
  levels: [],
  sectors: [],
  roles: [],
  skills: [],
  drivingLicenses: [],
  searchData: [],
  allSkills: [],
  certifications: {},
  sendFormSaving: false,
  showConfirmationModal: false,
  filters: {
    skills: [],
    roles: [],
    sectors: [],
    languages: [],
    locations: [],
    selectedFilters: [],
    name: null,
    radio: null,
    latitude: null,
    longitude: null,
    skillName: null,
    totalResults: null,
    currentPaginationPage: 1
  },
  filteredUsers: [],
  telegramSession: ''
})

export default {
  auth: {
    token: localStorage.getItem(ACCESS_TOKEN) || null,
    refreshToken: localStorage.getItem(ACCESS_REFRESH_TOKEN) || null
  },
  loggedUser: {},
  currentProfile: localStorage.getItem('currentProfile') || Profiles.PROFILE_TECHNICIAN,
  publicUser: {},
  languages: [],
  bookingEventTypes: [],
  bookingDocs: [],
  bookingOpened: [],
  bookingClosed: [],
  bookingExpired: [],
  technicianBookingNotifications: [],
  hardwareBookingNotifications: [],
  levels: [],
  sectors: [],
  roles: [],
  skills: [],
  drivingLicenses: [],
  searchData: [],
  allSkills: [],
  certifications: {},
  sendFormSaving: true,
  showConfirmationModal: false,
  filters: {
    skills: [],
    roles: [],
    sectors: [],
    languages: [],
    locations: [],
    selectedFilters: [],
    name: null,
    radio: null,
    latitude: null,
    longitude: null,
    skillName: null,
    totalResults: null,
    currentPaginationPage: 1
  },
  filteredUsers: [],
  telegramSession: ''
}
