import HardwareBrandFactory from '../domain/services/HardwareBrandFactory'
import HardwareFactory from '../domain/services/HardwareFactory'
import { HTTP } from '../services/http'

const URL_CREATE_TECHNICIAN_HARDWARE = 'api/users/me/technician/hardware'
const URL_UPDATE_TECHNICIAN_HARDWARE = 'api/users/me/technician/hardware/{id}'
const URL_DELETE_TECHNICIAN_HARDWARE = 'api/users/me/technician/hardware/{id}'
const URL_GET_HARDWARE_BRANDS = 'api/hardware-brands'

const headers = {
  'Content-Type': 'application/json'
}

export function getHardwareBrandsApi () {
  return HTTP.get(URL_GET_HARDWARE_BRANDS)
    .then(response => response.data.collection.map(hardwareBrand => HardwareBrandFactory.get(hardwareBrand)))
}

export function createHardwareApi (data) {
  return HTTP.post(URL_CREATE_TECHNICIAN_HARDWARE, data, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function updateHardwareApi (data) {
  const hardware = Object.assign({}, data)
  const url = URL_UPDATE_TECHNICIAN_HARDWARE.replace('{id}', hardware.id)
  delete hardware.id
  return HTTP.put(url, hardware, { headers })
    .then(response => HardwareFactory.get(response.data))
}

export function deleteHardwareApi (id) {
  const url = URL_DELETE_TECHNICIAN_HARDWARE.replace('{id}', id)
  return HTTP.delete(url)
    .then(response => response)
}
