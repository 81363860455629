import ProductType from '../models/ProductType'

class ProductTypeFactory {
  get (data) {
    const productType = {
      id: data.id,
      name: data.name
    }
    return new ProductType(productType)
  }
}

export default new ProductTypeFactory()
