import { getCommunityResults } from '../../../api/AvifySearchApi'
import { getUser, deleteMeApi } from '../../../api/AvifyApi'

export default {
  getAll ({ commit }) {
    const users = getCommunityResults().then(res => {
      commit('setUsers', res)
    })
    return users
  },
  getLogged ({ commit }) {
    getUser().then(res => {
      commit('setLogged', res.data)
      return res.data
    }).catch(error => {
      return error
    })
  },
  deleteMe () {
    return deleteMeApi()
      .then(res => res)
      .catch(error => Promise.reject(error))
  }
}
