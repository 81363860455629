import {
  fetchAll,
  fetchOneById,
  createRequest,
  updateRequest,
  deleteRequest,
  broadcastBookingRequest
} from '@/api/business/HardwareBookingApi'
import HardwareBookingFactory from '@/domain/services/HardwareBookingFactory'

export default {
  getAll ({ commit }, projectId) {
    return fetchAll(projectId)
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, payload) {
    return fetchOneById(payload.projectId, payload.id)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  create ({ commit }, payload) {
    return createRequest(payload.projectId, payload.data)
      .then(response => {
        commit('add', response)
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  update ({ commit }, payload) {
    return updateRequest(payload.projectId, payload.data)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  delete ({ commit }, payload) {
    return deleteRequest(payload.projectId, payload.id)
      .then(response => {
        commit('remove', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setEmpty ({ commit, rootGetters }) {
    commit('setEmpty', getEmpty(rootGetters.loggedUser))
  },
  broadcastBooking ({ commit }, payload) {
    return broadcastBookingRequest(payload.projectId, payload.data)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

function getEmpty (loggedUser) {
  return HardwareBookingFactory.get({
    createdBy: loggedUser.getCompleteName,
    eventType: null,
    docs: [],
    positions: [],
    location: null,
    code: null
  })
}
