import RoleFilter from '../../models/filters/RoleFilter'

class RoleFilterFactory {
  getRoleFilter (filterData) {
    const auth = {
      id: filterData.id,
      name: filterData.name
    }

    return new RoleFilter(auth)
  }
}

export default new RoleFilterFactory()
