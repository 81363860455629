import axios from 'axios'
import store from './../store/index'
import JWTMessages from './../plugins/jwt-messages'
import router from './../router/index'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

HTTP.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error)
)

const UNAUTHORIZED = 401
HTTP.interceptors.response.use(
  response => {
    store.dispatch('unlockSendFormSaving')
    return response
  },
  error => {
    const responseStatus = error.response.data.code
    const responseMessage = error.response.data.message
    const expiredJWT = responseStatus === UNAUTHORIZED && responseMessage === JWTMessages.expiredJWT

    if (responseStatus !== UNAUTHORIZED) {
      store.dispatch('unlockSendFormSaving')
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (expiredJWT) {
      store.dispatch('refreshToken')
        .then(() => {
          if (router.name !== 'dashboard') {
            router.push({ name: 'dashboard' })
          }
          return Promise.resolve()
        })
        .catch(error => {
          store.dispatch('unlockSendFormSaving')
          router.push({ name: 'login' })
          return Promise.reject(error)
        })
    }

    store.dispatch('unlockSendFormSaving')
    return Promise.reject(error)
  })
