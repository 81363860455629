import BookingSearchItem from '../models/BookingSearchItem'

class BookingSearchItemFactory {
  getBookingSearchItem (bookingData) {
    const booking = {
      id: bookingData.id,
      name: bookingData.name,
      code: bookingData.code,
      broadcasted: bookingData.broadcasted,
      opened: bookingData.opened,
      createdAt: bookingData.createdAt,
      location: bookingData.location,
      eventStartAt: bookingData.eventStartAt,
      eventEndAt: bookingData.eventEndAt,
      eventType: bookingData.eventType
    }

    return new BookingSearchItem(booking)
  }
}

export default new BookingSearchItemFactory()
