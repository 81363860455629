import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import errorMessagesUtil from './utils/getErrorMessagesUtil'

import './plugins/google-maps'
import './plugins/bootstrap-vue'
import './plugins/vee-validate'
import './plugins/uuid'
import './plugins/calendar'
import './plugins/vue-clipboard'
import './plugins/axios'
import './plugins/vue-search-select'
import './plugins/croppie'
import './plugins/nl2br'
import './plugins/vue-select'

import './style/custom.less'

import PublicTemplate from '@/templates/PublicTemplate.vue'
Vue.component('public-template', PublicTemplate)

Vue.config.productionTip = false

Vue.filter('defaultValue', function (value) {
  if (value === null) {
    return '-'
  }

  return value
})

Vue.mixin({
  methods: {
    getValidationState ({ validated, valid = null }) {
      if (!validated) {
        return null
      }

      if (!valid) {
        return false
      }
    },
    createFormFieldErrors (responseData) {
      const responseFieldErrors = {}
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        responseData.errors.forEach(error => {
          responseFieldErrors[error.field] = errorMessagesUtil.getErrorMessage(error.message)
        })
      }

      return responseFieldErrors
    },
    createFormErrors (responseData) {
      let responseFormErrors = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'error')) {
        responseFormErrors = errorMessagesUtil.getErrorMessage(responseData.message)
      }

      return responseFormErrors
    },
    lockScreen () {
      this.$store.dispatch('lockSendFormSaving')
    },
    unlockScreen () {
      this.$store.dispatch('unlockSendFormSaving')
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
