import SkillFilter from '../../models/filters/SkillFilter'

class SkillFilterFactory {
  getSkillFilter (skillFilterData) {
    const auth = {
      id: skillFilterData.id,
      name: skillFilterData.name
    }

    return new SkillFilter(auth)
  }
}

export default new SkillFilterFactory()
