import TechnicianBooking from '../models/TechnicianBooking'
import TechnicianBookingPositionFactory from './TechnicianBookingPositionFactory'

class TechnicianBookingFactory {
  get (bookingData) {
    const booking = {
      id: bookingData.id,
      name: bookingData.name,
      opened: bookingData.opened,
      code: bookingData.code,
      createdAt: bookingData.createdAt,
      createdBy: bookingData.createdBy,
      location: bookingData.location,
      eventStartAt: bookingData.eventStartAt,
      eventEndAt: bookingData.eventEndAt,
      eventType: bookingData.eventType,
      catering: bookingData.catering,
      broadcasted: bookingData.broadcasted,
      docs: bookingData.docs,
      docExtra: bookingData.docExtra,
      positions: iterateCreatePositions(bookingData.positions)
    }

    return new TechnicianBooking(booking)
  }
}

function iterateCreatePositions (positions) {
  if (positions) {
    return positions.map(position => TechnicianBookingPositionFactory.get(position))
  }
}

export default new TechnicianBookingFactory()
