import HardwareBooking from '../models/HardwareBooking'
import HardwareBookingPositionFactory from './HardwareBookingPositionFactory'

class HardwareBookingFactory {
  get (bookingData) {
    const booking = {
      id: bookingData.id,
      name: bookingData.name,
      opened: bookingData.opened,
      code: bookingData.code,
      createdAt: bookingData.createdAt,
      createdBy: bookingData.createdBy,
      location: bookingData.location,
      eventStartAt: bookingData.eventStartAt,
      eventEndAt: bookingData.eventEndAt,
      eventType: bookingData.eventType,
      broadcasted: bookingData.broadcasted,
      positions: iterateCreatePositions(bookingData.positions)
    }

    return new HardwareBooking(booking)
  }
}

function iterateCreatePositions (positions) {
  if (positions) {
    return positions.map(position => HardwareBookingPositionFactory.get(position))
  }
}

export default new HardwareBookingFactory()
