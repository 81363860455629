import BookingContactedTechnician from '../models/BookingContactedTechnician'

class BookingContactedTechnicianFactory {
  getBookingContactedTechnician (data) {
    const bookingContactedTechnician = {
      id: data.id,
      user: data.user,
      userTechnician: data.user.technician,
      technicianAccepted: data.technicianAccepted,
      businessAccepted: data.businessAccepted,
      favorite: data.favorite ? data.favorite : false
    }

    return new BookingContactedTechnician(bookingContactedTechnician)
  }
}

export default new BookingContactedTechnicianFactory()
