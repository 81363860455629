import HardwareBookingPositionSearchItem from '../models/HardwareBookingPositionSearchItem'

class HardwareBookingPositionSearchItemFactory {
  get (bookingData) {
    const bookingPosition = {
      id: bookingData.id,
      quantity: bookingData.quantity,
      opened: bookingData.opened,
      title: bookingData.title,
      annotations: bookingData.annotation,
      startDate: String(bookingData.startDate).replace(' ', 'T'),
      endDate: String(bookingData.endDate).replace(' ', 'T'),
      brand: bookingData.brand,
      productType: bookingData.type,
      hardwareBooking: bookingData.hardwareBooking
    }

    return new HardwareBookingPositionSearchItem(bookingPosition)
  }
}

export default new HardwareBookingPositionSearchItemFactory()
