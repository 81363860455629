export const HANDLER_NATIVE = 0
export const HANDLER_JOBANDTALENT = 1

export default class TechnicianBookingPositionSearchItem {
  constructor (bookingPositionData) {
    this.id = bookingPositionData.id
    this.pricePerDay = bookingPositionData.pricePerDay
    this.freelance = bookingPositionData.freelance
    this.opened = bookingPositionData.opened
    this.drivingLicenses = bookingPositionData.drivingLicenses
    this.roles = bookingPositionData.roles
    this.skills = bookingPositionData.skills
    this.title = bookingPositionData.title
    this.annotation = bookingPositionData.annotation
    this.startDate = bookingPositionData.startDate
    this.endDate = bookingPositionData.endDate
    this.hiredByAvify = bookingPositionData.hiredByAvify
    this.handler = bookingPositionData.handler

    this.location = bookingPositionData.technicianBooking.location
    this.createdAt = bookingPositionData.technicianBooking.createdAt
    this.eventStartAt = new Date(bookingPositionData.technicianBooking.eventStartAt.replace(' ', 'T'))
    this.eventEndAt = new Date(bookingPositionData.technicianBooking.eventEndAt.replace(' ', 'T'))
    this.catering = bookingPositionData.technicianBooking.catering

    this.businessName = bookingPositionData.technicianBooking.business.name
    this.businessAvatar = bookingPositionData.technicianBooking.business.avatar
  }

  get getId () {
    return this.id
  }

  get getPricePerDay () {
    return String(this.pricePerDay).replace('.', ',')
  }

  get getPrice () {
    return String(this.pricePerDay * this.getDayDifference()).replace('.', ',')
  }

  getDayDifference () {
    const diff = new Date(this.endDate) - new Date(this.startDate)
    return 1 + Math.floor(diff / 3600000 / 24)
  }

  get getFreelance () {
    return this.freelance
  }

  get getOpened () {
    return this.opened
  }

  get getDrivingLicenses () {
    return this.drivingLicenses
  }

  get hasDrivingLicenses () {
    return this.drivingLicenses.length > 0
  }

  get getRoles () {
    return this.roles
  }

  get hasRoles () {
    return this.roles.length > 0
  }

  get getTitle () {
    return this.title
  }

  get getAnnotation () {
    return this.annotation
  }

  get getSkills () {
    return this.skills
  }

  get hasSkills () {
    return this.skills.length > 0
  }

  get getStartDate () {
    if (!this.startDate) {
      return null
    }
    return new Date(this.startDate.replace(' ', 'T'))
  }

  get getEndDate () {
    if (!this.endDate) {
      return null
    }
    return new Date(this.endDate.replace(' ', 'T'))
  }

  get getCreatedAt () {
    return new Date(this.startDate.replace(' ', 'T'))
  }

  get isHiredByAvify () {
    return this.hiredByAvify
  }

  get getHandler () {
    return this.handler
  }

  get getNamedHandler () {
    return { avify: 'Native', jobandtalent: 'JobandTalent', workout: 'Workout' }[this.handler]
  }

  get getCatering () {
    return this.catering
  }

  get getEventDate () {
    const startDate = this.getStartDate
    const endDate = this.getEndDate
    const startMonthYear = startDate.getFullYear().toString() + startDate.getMonth().toString()
    const endMonthYear = endDate.getFullYear().toString() + endDate.getMonth().toString()
    if (startMonthYear === endMonthYear) {
      return this.getShortDate(startDate, endDate)
    }
    return this.getLongDate(startDate, endDate)
  }

  getLongDate (startDate, endDate) {
    return startDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(startDate.getMonth()) + ' ' +
      startDate.getFullYear() + ' - ' +
      endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  getShortDate (startDate, endDate) {
    return startDate.getDate() + ' - ' + endDate.getDate() + ' ' +
      this.getLocalizedMonthNameByMonth(endDate.getMonth()) + ' ' +
      endDate.getFullYear()
  }

  get getSimpleCreatedAt () {
    const hourDiff = (new Date() - this.getCreatedAt) / 1000 / 3600
    if (hourDiff < 24) {
      return this.simpleHours(hourDiff)
    }
    if (hourDiff < 720) {
      return this.simpleDays(hourDiff)
    }

    return 'Hace meses'
  }

  get getBusinessName () {
    return this.businessName
  }

  get getBusinessAvatar () {
    return this.businessAvatar
  }

  simpleHours (hours) {
    if (hours < 2) {
      return 'Hace 1 hora'
    }

    return 'Hace ' + Math.round(hours) + ' horas'
  }

  simpleDays (hours) {
    if (hours < 48) {
      return 'Hace 1 día'
    }

    return 'Hace ' + Math.round(hours / 24) + ' días'
  }

  getLocalizedMonthNameByMonth (month) {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ][month]
  }

  get getGoogleMapsUrl () {
    const address = `${this.location.name || ''}, ${this.location.administrative || ''}, ${this.location.country || ''}, ${this.location.county || ''}`

    const encodedAddress = encodeURI(address)

    return `https://www.google.com/maps?q=${encodedAddress}&output=embed`
  }

  get getOnlyTime () {
    const startAt = this.getStartDate
    const endAt = this.getEndDate
    return this.lpad(startAt.getHours()) + ':' +
      this.lpad(startAt.getMinutes()) + ' - ' +
      this.lpad(endAt.getHours()) + ':' +
      this.lpad(endAt.getMinutes())
  }

  lpad (str) {
    return str.toString().padStart(2, 0)
  }
}
