import JobCategory from '../models/JobCategory'

class JobCategoryFactory {
  get (data) {
    const jobCategory = {
      id: data.id,
      name: data.name
    }
    return new JobCategory(jobCategory)
  }
}

export default new JobCategoryFactory()
