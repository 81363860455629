import UserLevel from '../models/UserLevel'

class UserLevelFactory {
  getUserLevel (levelData) {
    const level = {
      id: levelData.id,
      name: levelData.name,
      code: levelData.code
    }

    return new UserLevel(level)
  }
}

export default new UserLevelFactory()
