import User from '../models/User'
import LanguageLevelFactory from './LanguageLevelFactory'
import CertificationFactory from './CertificationFactory'
import JobFactory from './JobFactory'
import HardwareFactory from './HardwareFactory'

class UserFactory {
  getUser (UserData) {
    const user = {
      id: UserData.id,
      lastTimeAcceptedTerms: UserData.lastTimeAcceptedTerms,
      email: UserData.email,
      firstName: UserData.firstName,
      lastName: UserData.lastName,
      slug: UserData.slug,
      business: null,
      technician: null
    }
    if (UserData.technician) {
      user.technician = {
        id: UserData.technician.id,
        birthday: UserData.technician.birthday,
        address: UserData.technician.address,
        postalCode: UserData.technician.postalCode,
        phoneNumber: UserData.technician.phone,
        gender: UserData.technician.gender,
        freelancer: UserData.technician.freelancer,
        description: UserData.technician.description,
        nickname: UserData.technician.nickname,
        linkedInProfile: UserData.technician.linkedInProfile,
        contactEmail: UserData.technician.contactEmail,
        avatar: UserData.technician.avatar,
        cover: UserData.technician.profileCover,
        languages: iterateCreateLanguagesLevel(UserData.technician.languages),
        sectors: UserData.technician.sectors,
        roles: UserData.technician.roles,
        skills: UserData.technician.skills,
        location: UserData.technician.location,
        drivingLicenses: UserData.technician.drivingLicenses,
        certifications: iterateCreateCertifications(UserData.technician.certifications),
        jobs: iterateCreateJobs(UserData.technician.jobs),
        hardware: iterateCreateHardware(UserData.technician.hardware),
        available: UserData.technician.available
      }
    }
    if (UserData.business) {
      user.business = {
        address: UserData.business.address,
        avatar: UserData.business.avatar,
        cif: UserData.business.cif,
        location: UserData.business.location,
        facebookProfile: UserData.business.facebookProfile,
        instagramProfile: UserData.business.instagramProfile,
        linkedInProfile: UserData.business.linkedInProfile,
        name: UserData.business.name,
        phoneNumber: UserData.business.phone,
        profileCover: UserData.business.profileCover,
        twitterProfile: UserData.business.twitterProfile,
        web: UserData.business.web,
        zipcode: UserData.business.zipcode,
        sectors: UserData.business.sectors,
        bookingCreator: UserData.business.bookingCreator
      }
    }
    return new User(user)
  }
}

function iterateCreateLanguagesLevel (languages) {
  return languages.map(language => LanguageLevelFactory.getLanguageLevel(language))
}

function iterateCreateCertifications (certifications) {
  return certifications.map(certification => CertificationFactory.getCertification(certification))
}

function iterateCreateJobs (jobs) {
  if (!jobs) {
    jobs = []
  }
  return jobs.map(job => JobFactory.get(job))
}

function iterateCreateHardware (hardware) {
  if (!hardware) {
    hardware = []
  }
  return hardware.map(hardware => HardwareFactory.get(hardware))
}
export default new UserFactory()
