import Vue from 'vue'

export default {
  setAll (state, payload) {
    Vue.set(state, 'all', [...payload])
  },
  add (state, payload) {
    state.all.push(payload)
  },
  remove (state, payload) {
    state.current = null
  },
  setCurrent (state, payload) {
    state.current = payload
  }
}
