import Venue from '@/domain/models/Venue'

class VenueFactory {
  getVenue (venueData) {
    const venue = {
      id: venueData.id,
      name: venueData.name,
      planimetry: venueData.planimetry,
      contacts: venueData.contacts,
      docExtra: venueData.docExtra,
      addresses: venueData.addresses
    }
    return new Venue(venue)
  }
}
export default new VenueFactory()
