import Vue from 'vue'

export default {
  setAll (state, payload) {
    Vue.set(state, 'all', [...payload])
  },
  add (state, payload) {
    state.add.push(payload)
  },
  remove (state, payload) {
    state.current = null
  },
  setEmpty (state) {
    state.current = getEmpty()
  },
  setCurrent (state, payload) {
    state.current = payload
  },
  setManagers (state, payload) {
    state.current.managers = payload
  },
  setNonManagers (state, payload) {
    state.current.nonManagers = payload
  },
  updateTeam (state, payload) {
    const team = state.current.teams.find(t => t.name === payload.name)
    team.members = payload.members
    team.groupLink = payload.groupLink
  },
  createTeam (state, payload) {
    const team = {
      name: payload,
      members: [],
      groupLink: null
    }
    state.current.teams.push(team)
  },
  removeTeam (state, payload) {
    const teamToDelete = state.current.teams.find(t => t.name === payload)
    if (teamToDelete.id) {
      if (!state.current.deletedTeams) {
        state.current = { ...state.current, deletedTeams: [] }
      }
      state.current.deletedTeams.push(teamToDelete.id)
    }
    state.current.teams = state.current.teams.filter(t => t.name !== payload)
  },
  setTeamGroupLink (state, payload) {
    const team = state.current.teams.find(t => t.name === payload.name)
    team.groupLink = payload.groupLink
  },
  setHardware (state, payload) {
    state.current.hardware = payload
  },
  addProjectDoc (state, payload) {
    state.current.docs.push(payload)
  },
  removeProjectDoc (state, payload) {
    if (!state.current.deletedDocs) {
      state.current = { ...state.current, deletedDocs: [] }
    }
    state.current.deletedDocs.push(payload)
    state.current.docs = state.current.docs.filter(pd => pd.id !== payload)
  },
  setAllExternalEmployees (state, payload) {
    Vue.set(state, 'externalEmployees', [...payload])
  },
  setAllExternalHardware (state, payload) {
    Vue.set(state, 'externalHardware', [...payload])
  }
}

function getEmpty () {
  return {
    id: null,
    name: '',
    venue: null,
    venueExtraName: null,
    startAt: '',
    endAt: '',
    managers: [],
    nonManagers: [],
    teams: [],
    docs: [],
    hardware: []
  }
}
