export default class Venue {
  constructor (venueData) {
    this.id = venueData.id
    this.name = venueData.name
    this.planimetry = venueData.planimetry
    this.contacts = venueData.contacts
    this.docExtra = venueData.docExtra
    this.addresses = venueData.addresses
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getPlanimetry () {
    return this.planimetry
  }

  get getContacts () {
    return this.contacts
  }

  get getDocExtra () {
    return this.docExtra
  }

  get getAddresses () {
    return this.addresses
  }
}
