import {
  fetchAll,
  fetchAllTechnician,
  fetchOneById,
  createRequest,
  updateRequest,
  deleteRequest
} from '@/api/business/EmployeeApi'

export default {
  getAll ({ commit }) {
    return fetchAll()
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getAllTechnician ({ commit }, id) {
    return fetchAllTechnician(id)
      .then(response => {
        commit('setAll', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getOneById ({ commit }, id) {
    return fetchOneById(id)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  create ({ commit }, payload) {
    return createRequest(payload)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  update ({ commit }, payload) {
    return updateRequest(payload)
      .then(response => {
        commit('setCurrent', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  delete ({ commit }, id) {
    return deleteRequest(id)
      .then(response => {
        commit('remove', response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
