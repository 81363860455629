export default class Booking {
  constructor (bookingData) {
    this.id = bookingData.id
    this.name = bookingData.name
    this.opened = bookingData.opened
    this.createdBy = bookingData.createdBy
    this.createdAt = bookingData.createdAt
    this.code = bookingData.code
    this.location = bookingData.location
    this.eventStartAt = bookingData.eventStartAt
    this.eventEndAt = bookingData.eventEndAt
    this.eventType = bookingData.eventType
    this.catering = bookingData.catering
    this.broadcasted = bookingData.broadcasted
    this.docs = bookingData.docs
    this.docExtra = bookingData.docExtra ? bookingData.docExtra.name : null
    this.positions = bookingData.positions
  }

  get getId () {
    return this.id
  }

  get getOpened () {
    return this.opened
  }

  updateOpened (opened) {
    this.opened = opened
  }

  get getName () {
    return this.name
  }

  get getCode () {
    return this.code
  }

  get getCreatedBy () {
    return this.createdBy
  }

  get getCreatedAt () {
    return new Date(this.createdAt.replace(' ', 'T'))
  }

  get getEventType () {
    return this.eventType
  }

  get getLocation () {
    return this.location
  }

  get getCompleteLocation () {
    if (!this.location) {
      return ''
    }
    return this.location.name + ', ' +
      this.location.administrative + ', ' +
      this.location.country
  }

  get getEventStartAt () {
    if (!this.eventStartAt) {
      return
    }
    return new Date(this.eventStartAt.replace(' ', 'T'))
  }

  get getEventEndAt () {
    if (!this.eventEndAt) {
      return
    }
    return new Date(this.eventEndAt.replace(' ', 'T'))
  }

  get getCatering () {
    return this.catering
  }

  get getBroadcasted () {
    return this.broadcasted
  }

  get getDocs () {
    return this.docs
  }

  get getDocExtra () {
    return this.docExtra
  }

  get getPositions () {
    return this.positions
  }

  get hasPositions () {
    return this.positions.length > 0
  }
}
