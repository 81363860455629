import SearchUserItem from '../models/SearchUserItem'

class SearchUserItemFactory {
  getUser (UserData) {
    const user = {
      id: UserData.id,
      firstName: UserData.firstName,
      lastName: UserData.lastName,
      technician: null
    }
    if (UserData.technician) {
      user.technician = {
        birthday: UserData.technician.birthday,
        address: UserData.technician.address,
        postalCode: UserData.technician.postalCode,
        phoneNumber: UserData.technician.phone,
        gender: UserData.technician.gender,
        freelancer: UserData.technician.freelancer,
        description: UserData.technician.description,
        nickname: UserData.technician.nickname,
        linkedInProfile: UserData.technician.linkedInProfile,
        contactEmail: UserData.technician.contactEmail,
        avatar: UserData.technician.avatar,
        cover: UserData.technician.profileCover,
        sectors: UserData.technician.sectors,
        roles: UserData.technician.roles,
        skills: UserData.technician.skills,
        location: UserData.technician.location,
        drivingLicenses: UserData.technician.drivingLicenses
      }
    }
    return new SearchUserItem(user)
  }
}

export default new SearchUserItemFactory()
