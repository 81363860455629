import TechnicianBookingPositionSearchItem from '../models/TechnicianBookingPositionSearchItem'

class TechnicianBookingPositionFactorySearchItemFactory {
  get (bookingData) {
    const bookingPosition = {
      id: bookingData.id,
      pricePerDay: bookingData.pricePerDay,
      language: bookingData.language,
      freelance: bookingData.freelance,
      opened: bookingData.opened,
      drivingLicenses: bookingData.drivingLicenses,
      roles: bookingData.roles,
      skills: bookingData.skills,
      title: bookingData.title,
      annotation: bookingData.annotation,
      startDate: String(bookingData.startDate).replace(' ', 'T'),
      endDate: String(bookingData.endDate).replace(' ', 'T'),
      hiredByAvify: bookingData.hiredByAvify,
      handler: bookingData.handler,
      technicianBooking: bookingData.technicianBooking
    }

    return new TechnicianBookingPositionSearchItem(bookingPosition)
  }
}

export default new TechnicianBookingPositionFactorySearchItemFactory()
