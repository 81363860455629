import EditableUserBusiness from '../models/EditableUserBusiness'

class EditableUserBusinessFactory {
  getEditableUserBusiness (UserData) {
    const userBusiness = {
      address: UserData.address,
      cif: UserData.cif,
      facebookProfile: UserData.facebookProfile,
      instagramProfile: UserData.instagramProfile,
      linkedInProfile: UserData.linkedInProfile,
      name: UserData.name,
      phoneNumber: UserData.phoneNumber,
      location: UserData.location,
      twitterProfile: UserData.twitterProfile,
      web: UserData.web,
      zipcode: UserData.zipcode,
      sectors: UserData.sectors
    }
    return new EditableUserBusiness(userBusiness)
  }
}

export default new EditableUserBusinessFactory()
