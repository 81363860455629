import LanguageLevel from '../models/LanguageLevel'
import UserLanguageFactory from './UserLanguageFactory'
import UserLevelFactory from './UserLevelFactory'

class LanguageLevelFactory {
  getLanguageLevel (languageLevelData) {
    const languageLevel = {
      id: languageLevelData.id,
      language: UserLanguageFactory.getUserLanguage(languageLevelData.language),
      level: UserLevelFactory.getUserLevel(languageLevelData.level)
    }
    return new LanguageLevel(languageLevel)
  }
}

export default new LanguageLevelFactory()
