import { HTTP } from '../services/http'

const URL_UPLOAD_TECHNICIAN_COVER = 'api/users/me/technician/cover'
const URL_UPLOAD_TECHNICIAN_AVATAR = 'api/users/me/technician/avatar'
const URL_UPLOAD_BUSINESS_COVER = 'api/users/me/business/cover'
const URL_UPLOAD_BUSINESS_AVATAR = 'api/users/me/business/avatar'

const headers = {
  'Content-Type': 'application/json'
}

export function uploadTechnicianCoverImageApi (imageData) {
  return HTTP.put(URL_UPLOAD_TECHNICIAN_COVER, imageData, { headers: headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function uploadTechnicianAvatarImageApi (imageData) {
  return HTTP.put(URL_UPLOAD_TECHNICIAN_AVATAR, imageData, { headers: headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function uploadBusinessCoverImageApi (imageData) {
  return HTTP.put(URL_UPLOAD_BUSINESS_COVER, imageData, { headers: headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function uploadBusinessAvatarImageApi (imageData) {
  return HTTP.put(URL_UPLOAD_BUSINESS_AVATAR, imageData, { headers: headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
