import { HTTP } from '@/services/http'
import HardwareBookingPositionFactory from '@/domain/services/HardwareBookingPositionFactory'
import HardwareBookingContactedFactory from '@/domain/services/HardwareBookingContactedFactory'
import HardwareBookingPositionSearchItemFactory from '@/domain/services/HardwareBookingPositionSearchItemFactory'
import BookingDeleteReasonFactory from '@/domain/services/BookingDeleteReasonFactory'

const URL = 'api/users/me/hardware-booking/'
const MIDDLE_URL = '/position'
const URL_HARDWARE = 'api/users/me/contactedHardware'
const URL_GET_HARDWARE_DELETED_REASONS = 'api/booking/hardware-booking-position/deleted-reasons'
const URL_HARDWARE_BOOKING_ADD_DELETE_REASONS = 'api/users/me/hardware-booking/{hardwareBookingId}/hardware-position/{hardwareBookingPositionId}/deleted-reasons'

const headers = {
  'Content-Type': 'application/json'
}

export function fetchAll (bookingId) {
  const url = URL + bookingId + MIDDLE_URL
  return HTTP.get(url)
    .then(response => response.data.collection.map(item => HardwareBookingPositionFactory.get(item)))
}

export function fetchOneById (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id
  return HTTP.get(url)
    .then(response => HardwareBookingPositionFactory.get(response.data))
}

export function createRequest (bookingId, data) {
  const url = URL + bookingId + MIDDLE_URL
  return HTTP.post(url, data, { headers: headers })
    .then(response => HardwareBookingPositionFactory.get(response.data))
}

export function updateRequest (bookingId, data) {
  const entityToUpdate = Object.assign({}, data)
  const url = URL + bookingId + MIDDLE_URL + '/' + entityToUpdate.id
  delete entityToUpdate.id
  delete entityToUpdate.opened
  return HTTP.put(url, entityToUpdate, { headers: headers })
    .then(response => HardwareBookingPositionFactory.get(response.data))
}

export function deleteRequest (bookingId, id, payload) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id
  const data = { bookingPositionDeletedReasonId: payload.bookingDeletedReasonId }
  return HTTP.delete(url, { data: data })
    .then(response => response)
}

export function fetchTotalContactedHardwareFromBusiness (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id + '/totalContactedHardware'
  return HTTP.get(url)
    .then(response => {
      return response.data.hardwareBookingTotalContacted
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function fetchContactedHardwareFromBusiness (bookingId, id) {
  const url = URL + bookingId + MIDDLE_URL + '/' + id + '/contactedHardware'
  return HTTP.get(url)
    .then(response => {
      return response.data.collection.map(contactedHardware => {
        return HardwareBookingContactedFactory
          .get(contactedHardware)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateHardwareBookingContactedFromBusiness (payload) {
  const url = URL + payload.bookingId + MIDDLE_URL + '/' + payload.positionId + '/contactedHardware' + '/' + payload.contactedId
  const updateData = { businessAccepted: payload.businessAccepted }
  return HTTP.put(url, updateData, { headers: headers })
    .then(response => {
      return HardwareBookingContactedFactory
        .get(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updateHardwareBookingContactedFromTechnician (payload) {
  const url = URL_HARDWARE + '/' + payload.positionId
  const updateData = { ownerAccepted: payload.ownerAccepted, ownerSawIt: true }
  return HTTP.put(url, updateData, { headers: headers })
    .then(response => {
      return HardwareBookingContactedFactory
        .get((response.data))
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getHardwarePositionsInboxApi () {
  const url = URL + 'positions?status=inbox'
  return HTTP.get(url)
    .then(res => {
      return res.data.collection.map((bookingPosition) => {
        return HardwareBookingPositionSearchItemFactory
          .get(bookingPosition)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getHardwarePositionsPendingApi () {
  const url = URL + 'positions?status=pending'
  return HTTP.get(url)
    .then(res => {
      return res.data.collection.map((bookingPosition) => {
        return HardwareBookingPositionSearchItemFactory
          .get(bookingPosition)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getHardwarePositionsAcceptedApi () {
  const url = URL + 'positions?status=accepted'
  return HTTP.get(url)
    .then(res => {
      return res.data.collection.map((bookingPosition) => {
        return HardwareBookingPositionSearchItemFactory
          .get(bookingPosition)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getHardwarePositionsRejectedApi () {
  const url = URL + 'positions?status=rejected'
  return HTTP.get(url)
    .then(res => {
      return res.data.collection.map((bookingPosition) => {
        return HardwareBookingPositionSearchItemFactory
          .get(bookingPosition)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getContactedHardwareTechnicianApi () {
  const url = URL_HARDWARE + '?status=unread'

  return HTTP.get(url)
    .then(res => res.data)
    .catch(error => {
      return Promise.reject(error)
    })
}
export function getDeleteReasonApi () {
  return HTTP.get(URL_GET_HARDWARE_DELETED_REASONS)
    .then(res => {
      return res.data.collection.map(bookingDeleteReason => {
        return BookingDeleteReasonFactory
          .getBookingDeleteReason(bookingDeleteReason)
      })
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function addDeleteReasonsApi (data) {
  const url = URL_HARDWARE_BOOKING_ADD_DELETE_REASONS
    .replace('{hardwareBookingId}', data.hardwareBookingId)
    .replace('{hardwareBookingPositionId}', data.hardwareBookingPositionId)

  delete data.hardwareBookingId
  delete data.hardwareBookingPositionId

  return HTTP.post(url, data, { headers: headers })
    .then(res => HardwareBookingPositionFactory.get(res.data))
    .catch(error => {
      return Promise.reject(error)
    })
}
