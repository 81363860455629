export default class Job {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.year = data.year
    this.roles = data.roles
    this.jobCategory = data.jobCategory
    this.link = data.link
    this.images = data.images
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getDescription () {
    return this.description
  }

  get getYear () {
    return this.year
  }

  get getRoles () {
    return this.roles
  }

  get getJobCategory () {
    return this.jobCategory
  }

  get getLink () {
    return this.link
  }

  get getImages () {
    return this.images
  }
}
