import UserLanguage from '../models/UserLanguage'

class UserLanguageFactory {
  getUserLanguage (languageData) {
    const language = {
      id: languageData.id,
      name: languageData.name,
      isoCode: languageData.isoCode
    }

    return new UserLanguage(language)
  }
}

export default new UserLanguageFactory()
