import TechnicianBookingContacted from '../models/TechnicianBookingContacted'

class TechnicianBookingContactedFactory {
  get (data) {
    const technicianBookingContacted = {
      id: data.id,
      user: data.user,
      userTechnician: data.user.technician,
      technicianAccepted: data.technicianAccepted,
      businessAccepted: data.businessAccepted,
      favorite: data.favorite ? data.favorite : false
    }

    return new TechnicianBookingContacted(technicianBookingContacted)
  }
}

export default new TechnicianBookingContactedFactory()
