import EditableUser from '../models/EditableUser'
import EditableUserBusinessFactory from '@/domain/services/EditableUserBusinessFactory'
import EditableUserTechnicianFactory from '@/domain/services/EditableUserTechnicianFactory'

class EditableUserFactory {
  getEditableUser (UserData) {
    const user = {
      id: UserData.id,
      email: UserData.email,
      firstName: UserData.firstName,
      lastName: UserData.lastName
    }
    if (UserData.technician) {
      user.technician = EditableUserTechnicianFactory.getEditableUserTechnician(UserData.technician)
    }
    if (UserData.business) {
      user.business = EditableUserBusinessFactory.getEditableUserBusiness(UserData.business)
    }
    return new EditableUser(user)
  }
}

export default new EditableUserFactory()
