import axios from '../services'
import AuthFactory from '../domain/services/AuthFactory'

const URL_GET_JWT_TOKEN = '/api/auth'
const URL_GET_JWT_REFRESH_TOKEN = '/api/auth/refresh-tokens'
const URL_REQUEST_RESET_PASSWORD = '/api/auth/password-recovery'
const URL_REQUEST_UPDATE_PASSWORD_RECOVERY = '/api/auth/update-password-from-recovery'
const URL_REQUEST_VALIDATE_EMAIL = '/api/users/email-validations'
const URL_REQUEST_GOOGLE_CONSENT = '/api/auth/consent/google'
const URL_REQUEST_FACEBOOK_CONSENT = '/api/auth/consent/facebook'
const URL_REQUEST_APPLE_CONSENT = 'api/auth/consent/apple'
const URL_REQUEST_GOOGLE_LOGIN = '/api/auth/google'
const URL_REQUEST_FACEBOOK_LOGIN = '/api/auth/facebook'
const URL_REQUEST_APPLE_LOGIN = '/api/auth/apple'
const ACCESS_REFRESH_TOKEN = 'access_refresh_token'

const axiosHeadersConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export function getJwtToken (credentials) {
  return axios
    .post(URL_GET_JWT_TOKEN, credentials)
    .then(res => {
      AuthFactory.getAuth(res.data)
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function updatePasswordFromRecoveryApi (updatePasswordData) {
  return axios
    .post(URL_REQUEST_UPDATE_PASSWORD_RECOVERY, updatePasswordData, axiosHeadersConfig)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getEmailValidationApi (emailValidationData) {
  return axios
    .post(URL_REQUEST_VALIDATE_EMAIL, emailValidationData, axiosHeadersConfig)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error)
    })
}

export function getRefreshToken () {
  const refreshToken = { refreshToken: localStorage.getItem(ACCESS_REFRESH_TOKEN) }
  return axios
    .post(URL_GET_JWT_REFRESH_TOKEN, refreshToken, axiosHeadersConfig)
    .then(res => res)
}

export function getRecoveryPassword (recoveryPassword) {
  return axios
    .post(URL_REQUEST_RESET_PASSWORD, recoveryPassword, axiosHeadersConfig)
    .then(res => res)
}

export function getGoogleConsentUrl () {
  return axios
    .get(URL_REQUEST_GOOGLE_CONSENT)
    .then(res => res)
}

export function getFacebookConsentUrl () {
  return axios
    .get(URL_REQUEST_FACEBOOK_CONSENT)
    .then(res => res)
}

export function getAppleConsentUrl () {
  return axios
    .get(URL_REQUEST_APPLE_CONSENT)
    .then(res => res)
}

export function getGoogleLoginUrl (googleLoginData) {
  return axios
    .post(URL_REQUEST_GOOGLE_LOGIN, null, { params: googleLoginData }, axiosHeadersConfig)
    .then(res => res)
}

export function getFacebookLoginUrl (facebookLoginData) {
  return axios
    .post(URL_REQUEST_FACEBOOK_LOGIN, null, { params: facebookLoginData }, axiosHeadersConfig)
    .then(res => res)
}

export function getAppleLoginUrl (appleLoginData) {
  return axios
    .post(URL_REQUEST_APPLE_LOGIN, appleLoginData, { headers: axiosHeadersConfig, params: appleLoginData })
    .then(res => res)
}
