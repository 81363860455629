
export default class JWTMessages {
  static get expiredJWT () {
    return 'Expired JWT Token'
  }

  static get invalidJWT () {
    return 'Invalid JWT Token'
  }
}
