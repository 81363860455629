import HardwareBookingPosition from '../models/HardwareBookingPosition'

class HardwareBookingPositionFactory {
  get (bookingData) {
    const bookingPosition = {
      id: bookingData.id,
      quantity: bookingData.quantity,
      opened: bookingData.opened,
      title: bookingData.title,
      annotation: bookingData.annotation,
      startDate: String(bookingData.startDate).replace(' ', 'T'),
      endDate: String(bookingData.endDate).replace(' ', 'T'),
      brand: bookingData.brand,
      type: bookingData.type,
      hardwareBooking: bookingData.hardwareBooking
    }

    return new HardwareBookingPosition(bookingPosition)
  }
}

export default new HardwareBookingPositionFactory()
