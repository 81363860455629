export default class Certification {
  constructor (certificationData) {
    this.id = certificationData.id
    this.centerName = certificationData.centerName
    this.name = certificationData.name
    this.observations = certificationData.observations
    this.startAtYear = certificationData.startAtYear
    this.startAtMonth = certificationData.startAtMonth
    this.finishedAtYear = certificationData.finishedAtYear
    this.finishedAtMonth = certificationData.finishedAtMonth
    this.expirationYear = certificationData.expirationYear
    this.expirationMonth = certificationData.expirationMonth
  }

  get getId () {
    return this.id
  }

  get getCenterName () {
    return this.centerName
  }

  get getName () {
    return this.name
  }

  get getDatesInformation () {
    return this.constructStartAtDate() + ' · ' + this.constructExpeditionDate()
  }

  get getExpirationDatesInformation () {
    return this.constructExpirationAtDate()
  }

  constructStartAtDate () {
    if (this.attributeHasValue(this.startAtYear)) {
      if (this.attributeHasValue(this.startAtMonth)) {
        return 'Fecha de inicio: ' + this.getMonthName(this.startAtMonth) + ' de ' + this.startAtYear
      }

      return 'Fecha de inicio: ' + this.startAtYear
    }

    return 'Sin fecha de inicio'
  }

  constructExpeditionDate () {
    if (this.attributeHasValue(this.finishedAtYear)) {
      if (this.attributeHasValue(this.finishedAtMonth)) {
        return 'Expedición: ' + this.getMonthName(this.finishedAtMonth) + ' de ' + this.finishedAtYear
      }

      return 'Expedición: ' + this.finishedAtYear
    }

    return 'Sin fecha de expedición'
  }

  constructExpirationAtDate () {
    if (this.attributeHasValue(this.expirationYear)) {
      if (this.attributeHasValue(this.expirationMonth)) {
        return 'Vencimiento: ' + this.getMonthName(this.expirationMonth) + ' de ' + this.expirationYear
      }

      return 'Vencimiento: ' + this.expirationYear
    }

    return 'Sin fecha de vencimiento'
  }

  attributeHasValue (data) {
    return (data !== null) && (data !== undefined) && (data !== '') && (data !== 'NULL')
  }

  getMonthName (month) {
    const months = {
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    }

    return months[month]
  }

  update (certification) {
    Object.assign(this, certification)
  }

  get getObservations () {
    return this.observations
  }
}
