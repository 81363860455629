<template>
  <div class="public-template">
    <b-navbar fixed="top" :sticky="true" class="navbar-pubic">
      <b-navbar-brand href="https://avify.net/">
        <Logo></Logo>
      </b-navbar-brand>
    </b-navbar>
    <b-container class="content">
      <slot name="public-template"></slot>
    </b-container>
  </div>
</template>
<script>

import Logo from '@/components/Header/Logo/Logo.vue'

export default {
  name: 'PublicTemplate',
  components: {
    Logo
  }
}
</script>

<style scoped lang="less">
  @import 'publicTemplate.less';
</style>
